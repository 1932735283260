import { PrimaryButton, WarningButton } from "components/Buttons";
import dayjs from "dayjs";
import FormikWrapper from "components/FormikWrapper";
import { getInvoicesRaport } from "connector";
import DateInput from "components/FormikWrapper/FormControls/Date";
import SelectInput from "components/FormikWrapper/FormControls/Select";
import { useConfig } from "providers/configProvider";
import formatDatesByKeys from "utils/formatDatesByKeys";

const InvoicesRaportGenerator = ({ type, onClose }) => {
  const { INVOICE_TYPES_DICT, PAY_STATUSES_DICT, INVOICE_FORMATS_DICT } =
    useConfig();

  const onSubmit = async (values) => {
    const valuesToSend = formatDatesByKeys({
      keys: ["createDate_min", "createDate_max"],
      data: values,
    });

    const payload = await getInvoicesRaport({
      type,
      params: { filters: valuesToSend },
    });
    const blob =
      type === "epp"
        ? new Blob([payload], { type: "text/plain;charset=windows-1250" })
        : new Blob([payload], { type: `text/${type}` });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `plik.${type}`; // albo dowolna nazwa z rozszerzeniem
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <div className="InvoicesRaportGenerator-Module">
      <h2>Generowanie raportu {type.toUpperCase()}</h2>
      <FormikWrapper
        initialValues={{
          createDate_min: dayjs().startOf("month"),
          createDate_max: dayjs().endOf("month"),
        }}
        onSubmit={onSubmit}
      >
        <DateInput id="createDate_min" placeholder="Data od" />
        <DateInput id="createDate_max" placeholder="Data do" />
        <SelectInput
          id="invoiceType"
          placeholder="Typ"
          config={INVOICE_TYPES_DICT}
        />
        <SelectInput
          id="payStatus"
          placeholder="Status płatności"
          config={PAY_STATUSES_DICT}
        />
        <SelectInput
          id="format"
          placeholder="Format"
          config={INVOICE_FORMATS_DICT}
          noNumberKey={true}
        />
        <div className="button-container">
          <PrimaryButton htmlType="submit" onClick={() => onClose()}>
            Generuj
          </PrimaryButton>
          <WarningButton onClick={onClose}>Anuluj</WarningButton>
        </div>
      </FormikWrapper>
    </div>
  );
};

export default InvoicesRaportGenerator;
