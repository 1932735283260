import React, { useState } from "react";
import { getSettings, postSetting } from "connector";
import "./Settings.scss"
import { SuccessButton } from "components/Buttons";
import FetchWrapper from "components/FetchWrapper";

const SettingsEditForm = ({data, refetch}) => {
  const [settings, setSettings] = useState(
    data.map((setting) => ({
      ...setting,
      defaultValue: setting.value,
    }))
  );

  const handleInputChange = (event, id) => {
    const updatedSettings = settings.map((setting) => {
      if (setting.id === id) {
        return { ...setting, value: event.target.value };
      }
      return setting;
    });
    setSettings(updatedSettings);
  };

  const handleSubmit = (id, newValue) => {
    const payload = postSetting({ id, value: newValue })
      .then((payload) => {
      alert(`Ustawienie zostało zapisane`)
      refetch();
      return payload
    });
  };

  return (
    <div className="settings-page">
      <h3 className="title">Ustawienia</h3>
      {settings.map((setting) => (
        <form className="setting-row" key={setting.id} onSubmit={(e) => e.preventDefault()}>
          <label>{setting.name}</label>
          <input
            type="text"
            value={setting.value}
            onChange={(e) => handleInputChange(e, setting.id)}
          />
          <SuccessButton
            type="primary"
            className={setting.value.trim() === "" || setting.value === setting.defaultValue ? "hidden" : ""}
            onClick={() => handleSubmit(setting.id, setting.value)}
          >
            Wyślij
          </SuccessButton>
          
        </form>
      ))}
    </div>
  );
};

const SettingsForm = () => (
  <FetchWrapper
    component={SettingsEditForm}
    connector={() => getSettings({ params: "" })}
    errorMessage="Nie można pobrać ustawień"
  />
)

export default SettingsForm;
