import FormikWrapper from "components/FormikWrapper";
import TextInput from "components/FormikWrapper/FormControls/Input";
import { Button } from "antd";

const SearchForm = ({ initialValues = {}, onSearch, onClear, onClose }) => {
  return (
    <div className="SearchForm-Module">
      <h2>Filtr kategorii</h2>
      <FormikWrapper initialValues={initialValues} onSubmit={onSearch}>
        <TextInput id="name" placeholder="Nazwa" />
        <TextInput id="description" placeholder="Opis" />
          
        <div className="button-container">
          <Button
            htmlType="submit"
            color="green"
            variant="solid"
            onClick={(values) => {
              onClose();
            }}
          >
            Filtruj
          </Button>
          <Button
            htmlType="reset"
            onClick={(value) => {
              onClear(value);
              onClose();
            }}
            color="red"
            variant="solid"
          >
            Wyczyść
          </Button>
          <Button onClick={onClose} color="yellow" variant="solid">
            Anuluj
          </Button>
        </div>
      </FormikWrapper>
    </div>
  );
};

export default SearchForm;
