import FetchWrapper from "components/FetchWrapper";
import React, { useState } from "react";
import { getLocations } from "connector";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import objectToQueryString from "utils/objectToQueryString";
import { SuccessButton } from "components/Buttons";

const ItemType = "LOCATION";

const DraggableLocation = ({ item, index, moveItem, locationsObject }) => {
  const [, ref] = useDrag({
    type: ItemType,
    item: { index },
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveItem(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  const getName = (id) => {
    const location = locationsObject.find(
      ({ id: locationId }) => locationId === id
    );

    return (
      (
        <div>
          {index + 1}. {location?.name},
          <br />
          {location.address}, {location.city}`
        </div>
      ) || "Nieznana lokalizacja"
    );
  };

  return (
    <div ref={(node) => ref(drop(node))} className="location-item">
      {getName(item.locationId)}
    </div>
  );
};

const LocationSorter = ({
  ordersConfig,
  setFieldValue,
  data: payload,
  onClose,
}) => {
  const data = payload?.data || [];
  const [localOrders, setLocalOrders] = useState(ordersConfig);

  const moveItem = (fromIndex, toIndex) => {
    const updatedConfig = [...localOrders];
    const [movedItem] = updatedConfig.splice(fromIndex, 1);
    updatedConfig.splice(toIndex, 0, movedItem);
    setLocalOrders(updatedConfig);
  };

  const handleSave = () => {
    setFieldValue("ordersConfig", localOrders);
    onClose();
  };

  return (
    <div className="LocationSorter-Component">
      <h3>Sortowanie lokalizacji</h3>
      <DndProvider backend={HTML5Backend}>
        <div className="list">
          {localOrders.map((item, index) => (
            <DraggableLocation
              key={item.locationId}
              item={item}
              index={index}
              moveItem={moveItem}
              locationsObject={data}
            />
          ))}
        </div>
        <SuccessButton onClick={handleSave}>Zapisz</SuccessButton>
      </DndProvider>
    </div>
  );
};

const LocationSorterWrapper = ({ ordersConfig, setFieldValue, onClose }) => {
  const ids = ordersConfig.map(({ locationId }) => locationId);

  return (
    <FetchWrapper
      component={LocationSorter}
      connector={() =>
        getLocations({
          params: objectToQueryString({
            filters: { id: ids.join([","]) },
            pagination: { pageSize: ids.length },
          }),
        })
      }
      ordersConfig={ordersConfig}
      setFieldValue={setFieldValue}
      onClose={onClose}
    />
  );
};

export default LocationSorterWrapper;
