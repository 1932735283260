import { useEffect } from "react";
import { initialValues } from "./initialValues";
import dayjs from "dayjs";
import {
  getWasteCardById,
  postWasteCard,
  getNextWasteCardNumber,
  deleteWasteCard,
  getWasteCardPDF,
} from "connector";
import FormikWrapper from "components/FormikWrapper";
import TextInput from "components/FormikWrapper/FormControls/Input";
import DateInput from "components/FormikWrapper/FormControls/Date";
import "./WasteCardEditForm.scss";
import { useFormikContext } from "formik";
import FetchWrapper from "components/FetchWrapper";
import FetchListInput from "components/FormikWrapper/FormControls/FetchList";
import TextareaInput from "components/FormikWrapper/FormControls/Textarea";
import CheckboxInput from "components/FormikWrapper/FormControls/Checkbox";
import DeleteRowButton from "components/DeleteRowButton";
import ButtonsFooter from "components/FormikWrapper/FormControls/FooterButtons";
import validationSchema from "./WasteCardEditForm.validation";
import { PrimaryButton } from "components/Buttons";

const InitForm = () => {
  return (
    <>
      <FetchListInput configKey="drivers" />
      <DateInput id="transferDate" placeholder="Data odebrania" />
    </>
  );
};

const Form = ({ onClose, wasteCardId, clientId }) => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (clientId) {
      setFieldValue("clientId", clientId);
    }
  }, [clientId]);

  useEffect(() => {
    if (
      !wasteCardId ||
      (wasteCardId === "new" && values.driverId && values.transferDate)
    ) {
      getNextWasteCardNumber({
        driverId: values.driverId,
        transferDate: values.transferDate,
      }).then((response) => {
        setFieldValue("wasteNumber", response.number);
        setFieldValue("counter", response.counter);
        setFieldValue("format", response.format);
      });
    }
  }, [values.driverId, values.transferDate]);

  useEffect(() => {
    if (values.noInvoice) {
      setFieldValue("invoiceId", null);
    }
  }, [values.noInvoice]);

  if (!values.driverId || !values.transferDate) {
    return <InitForm />;
  }

  const handleWasteCardPDF = async () => {
    const payload = await getWasteCardPDF({ id: wasteCardId });
    const blob = new Blob([payload], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    window.open(url);
  };

  return (
    <div className="WasteCardForm-form">
      <TextInput id="wasteNumber" placeholder="Nazwa" />
      <DateInput id="transferDate" placeholder="Data odebrania" />
      <FetchListInput configKey="clients" />
      <FetchListInput configKey="locations" />
      <FetchListInput configKey="drivers" />
      <TextInput id="quantity" placeholder="Ilość" />
      {!values?.noInvoice && values.id && (
        <>
          <FetchListInput configKey="invoices" />
        </>
      )}
      <CheckboxInput id="noInvoice" placeholder="Bez faktury" />
      <TextareaInput id="notes" placeholder="Notatka" />

      <ButtonsFooter onClose={onClose}>
        {wasteCardId && (
          <>
            <DeleteRowButton
              id={wasteCardId}
              connector={deleteWasteCard}
              onAfterRemove={onClose}
            />
            <PrimaryButton onClick={() => handleWasteCardPDF()}>
              Drukuj
            </PrimaryButton>
          </>
        )}
      </ButtonsFooter>
    </div>
  );
};

const WasteCardForm = ({ data: product, onClose, wasteCardId, clientId }) => {
  const updateDates = (wasteCard) => {
    const { transferDate } = wasteCard;
    return {
      ...wasteCard,
      transferDate: dayjs(transferDate).format("YYYY-MM-DD"),
    };
  };

  const onSubmit = (values) => {
    const fixedValues = updateDates(values);

    postWasteCard({ data: fixedValues }).then(() => {
      onClose();
    });
  };

  return (
    <div className="WasteCardForm-Module">
      <FormikWrapper
        initialValues={initialValues(product)}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <Form onClose={onClose} wasteCardId={wasteCardId} clientId={clientId} />
      </FormikWrapper>
    </div>
  );
};

const WasteCardFormWrapper = ({ id, onClose, clientId }) => (
  <FetchWrapper
    wasteCardId={id}
    component={WasteCardForm}
    id={id}
    connector={() => getWasteCardById({ id })}
    onClose={onClose}
    clientId={clientId}
    errorMessage={"Nie można pobrać karty odpadów."}
  />
);

export default WasteCardFormWrapper;
