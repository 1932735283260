import Popup2 from "components/Popup2";
import { PrimaryButton } from "components/Buttons";
import WasteCardXLSGenerator from "modules/WasteCardXLSGenerator";
import WasteCardsTable from "modules/WasteCardsTable";

const WasteCards = () => {
  return (
    <div>
      <WasteCardsTable
        headerChildren={
          <Popup2
            component={WasteCardXLSGenerator}
            ButtonComponent={PrimaryButton}
            openButtonText="Generuj listę zbiorczą"
            modalProps={{ width: 630 }}
          />
        }
      />
    </div>
  );
};

export default WasteCards;
