export const columnsConfig = () => [
  {
    title: "Name",
    dataIndex: "name",
    sorter: true,
    width: "20%",
  },
  {
    title: "Właściciel",
    dataIndex: "clientName",
    sorter: true,
  },
  {
    title: "Miasto",
    dataIndex: "city",
    sorter: true,
  },
  {
    title: "Adres",
    render: (data) => `${data.postalCode}, ${data.address}`,
  },
  {
    title: "NIP",
    dataIndex: "nip",
  },
  {
    title: "Telefon",
    dataIndex: "phone",
  },
  {
    title: "Email",
    dataIndex: "email",
  },
];
