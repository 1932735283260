import React, { createContext, useState, useEffect, useContext } from "react";
import Login from "pages/Login";
import axios from "axios";

export const AuthContext = createContext();

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
};

export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);

  useEffect(() => {
    if (localStorage.getItem("authToken")) {
      const token = localStorage.getItem("authToken");
      const checkResponse = async () => {
        axios
          .post(`${process.env.REACT_APP_API_PATH}/check-login`, {
            token,
          })
          .then((response) => {
            if (response.data.login) {
              setAuthToken(token);
              const refreshToken = localStorage.getItem("refreshToken");
              setRefreshToken(refreshToken);
            }
          })
          .catch(() => {
            localStorage.removeItem("authToken");
            localStorage.removeItem("refreshToken");
          });
      };

      checkResponse();
    } else {
      localStorage.removeItem("authToken");
      localStorage.removeItem("refreshToken");
    }
  }, []);

  useEffect(() => {
    if (!authToken) return;
    const interval = setInterval(refresh, 1000 * 60 * 14);
    return () => clearInterval(interval);
  }, [authToken]);

  const login = async (login, password) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_PATH}/login`,
        { login, password }
      );

      // Sprawdź, czy odpowiedź zawiera tokeny, jeśli nie - rzuć wyjątek z tekstem błędu
      if (!response.data.authToken || !response.data.refreshToken) {
        throw new Error(response.data); // Zakładając, że response.data zawiera tekst błędu
      }

      const { authToken, refreshToken } = response.data;

      localStorage.setItem("authToken", authToken);
      localStorage.setItem("refreshToken", refreshToken);

      setAuthToken(authToken);
      setRefreshToken(refreshToken);
    } catch (error) {
      console.error("Błąd logowania:", error);
      return { error: error.message };
      // document.showAlert(true, "Błąd", "Błędny login lub hasło");
    }
  };

  const logout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("refreshToken");
    setAuthToken(null);
    setRefreshToken(null);
  };

  const refresh = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_PATH}/refresh`,
        { refreshToken }
      );
      const { authToken: newAuthToken } = response.data;

      localStorage.setItem("authToken", newAuthToken);

      setAuthToken(newAuthToken);
    } catch (error) {
      console.error("Błąd odświeżania tokena:", error);
      logout();
    }
  };

  const authContextValue = {
    authToken,
    refreshToken,
    login,
    logout,
    refresh,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {authToken ? children : <Login />}
    </AuthContext.Provider>
  );
};
