import React from "react";
import { DatePicker } from "antd";
import { useFormikContext, getIn } from "formik";
import dayjs from "dayjs";

const DateInput = ({ id, placeholder }) => {
  const formikContext = useFormikContext();

  const value = getIn(formikContext.values, id)
    ? dayjs(getIn(formikContext.values, id))
    : null;

  const handleChange = (date) => {
    formikContext.setFieldValue(
      id,
      date ? dayjs(date).format("YYYY-MM-DD") : null
    );
  };

  return (
    <div className="date-input-wrapper">
      <label htmlFor={id}>{placeholder}</label>
      <DatePicker onChange={handleChange} value={value} format="YYYY-MM-DD" />
      {formikContext.errors[id] && (
        <span className="validation-error-description">
          {formikContext.errors[id]}
        </span>
      )}
    </div>
  );
};

export default DateInput;
