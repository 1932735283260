import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  wasteNumber: Yup.string().required("Nazwa jest wymagana"),
  transferDate: Yup.date().required("Data odbioru jest wymagana"),
  clientId: Yup.string().required("Klient jest wymagany"),
  locationId: Yup.string().required("Lokalizacja jest wymagana"),
  driverId: Yup.string().required("Kierowca jest wymagany"),
  quantity: Yup.number()
    .min(0.01, "Ilość musi być większa niż 0")
    .required("Ilość jest wymagana"),
  invoice: Yup.string().notRequired(),
  noInvoice: Yup.boolean().notRequired(),
});

export default validationSchema;
