import { getLocationById, postLocation, deleteLocation } from "connector";
import FetchWrapper from "components/FetchWrapper";
import FormikWrapper from "components/FormikWrapper";
import TextInput from "components/FormikWrapper/FormControls/Input";

import { initialValues } from "./initialValues";
import "./LocationEditForm.scss";
import ButtonsFooter from "components/FormikWrapper/FormControls/FooterButtons";
import FetchListInput from "components/FormikWrapper/FormControls/FetchList";
import TimeInput from "components/FormikWrapper/FormControls/Time";
import DeleteRowButton from "components/DeleteRowButton";

import validationSchema from "./LocalisationEditForm.validation";

const LocationEditForm = ({ data: location, onClose, clientId }) => {
  const onSubmit = (values) => {
    postLocation({ data: values }).then(() => {
      onClose();
    });
  };

  const initialFormikValues = { ...location };
  if (clientId) {
    initialFormikValues.clientId = clientId;
  }

  return (
    <div className="LocationEditForm-Module">
      <FormikWrapper
        initialValues={initialValues(initialFormikValues)}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <div className="LocationEditForm-module">
          <FetchListInput configKey="clients" />
          <TextInput id="name" placeholder="Nazwa" />
          <TextInput id="address" placeholder="Adres" />
          <TextInput id="postalCode" placeholder="Kod pocztowy" />
          <TextInput id="city" placeholder="Miasto" />
          <TextInput id="country" placeholder="Kraj" />
          <TextInput id="phone" placeholder="Telefon" />
          <TextInput id="email" placeholder="Email" />
          <TimeInput id="openingTime" placeholder="Godzina otwarcia" />
          <TimeInput id="closingTime" placeholder="Godzina zamknięcia" />
          <TextInput id="additionalInfo" placeholder="Dodatkowe informacje" />
        </div>
        <ButtonsFooter onClose={onClose}>
          {location.id && (
            <DeleteRowButton
              id={location.id}
              connector={deleteLocation}
              onAfterRemove={onClose}
            />
          )}
        </ButtonsFooter>
      </FormikWrapper>
    </div>
  );
};

const LocationEditFormWrapper = ({ id = "new", onClose, clientId }) => (
  <FetchWrapper
    onClose={onClose}
    component={LocationEditForm}
    clientId={clientId}
    id={id}
    connector={() => getLocationById({ id })}
    errorMessage="Nie można pobrać danych lokalizacji."
  />
);

export default LocationEditFormWrapper;
