import AdvancedTable2 from "components/AdwancedTable2";
import { columnsConfig } from "./config";
import SearchForm from "./SearchForm";
import { getWasteCardsByClientId, getWasteCards } from "connector";
import WasteCardEditForm from "modules/WasteCardEditForm";

const WasteCardsTable = ({ clientId, locationId, ...props }) => {
  const onFetch = (params) => {
    return clientId
      ? getWasteCardsByClientId({ ...params, clientId })
      : getWasteCards(params);
  };

  return (
    <div>
      <AdvancedTable2
        title="Karty odpadów"
        columns={columnsConfig()}
        onFetch={onFetch}
        FilterComponent={SearchForm}
        EditAddComponent={WasteCardEditForm}
        clientId={clientId}
        {...props}
      />
    </div>
  );
};

export default WasteCardsTable;
