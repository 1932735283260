import { useParams } from "react-router-dom";

import InvoiceEditForm from "modules/InvoiceEditForm";
import DeleteRowButton from "components/DeleteRowButton";
import HeaderCard from "components/HeaderCard";
import { deleteInvoice } from "connector";

const InvoiceDetails = () => {
  const { id } = useParams();

  return (
    <div>
      <HeaderCard title="Szczegóły faktury">
        <DeleteRowButton
          connector={deleteInvoice}
          id={id}
          onAfterRemove={() => {}}
        />
      </HeaderCard>
      <InvoiceEditForm invoiceId={id} />
    </div>
  );
};

export default InvoiceDetails;
