export const calculateNetto = (brutto, vat, quantity) =>
  (brutto / (1 + vat / 100) / quantity).toFixed(2);
export const calculateBrutto = (netto, vat, quantity) =>
  (netto * (1 + vat / 100) * quantity).toFixed(2);

export const calculateSummaryNetto = (invoiceItems) => {
  return invoiceItems
    .reduce(
      (sum, item) =>
        sum + parseFloat(item.nettoUnitPrice) * parseFloat(item.quantity),
      0
    )
    .toFixed(2);
};

export const calculateSummaryBrutto = (invoiceItems) => {
  return invoiceItems
    .reduce((sum, item) => sum + parseFloat(item.bruttoSummaryPrice || 0), 0)
    .toFixed(2);
};
// Suggested variables:
// nettoUnitPrice
// nettoSummaryPrice
// vat
// bruttoUnitPrice
// bruttoSummaryPrice
// unit
// quantity
// totalQuantity
