import { MinusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import TextInput from "components/FormikWrapper/FormControls/Input";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import { calculateBrutto, calculateNetto } from "utils/cashUtils";
import { sanitizeQuantity } from "utils/sanitizeQuantity";

const ItemCorrectEdit = ({ index }) => {
  const formikContext = useFormikContext();
  const {
    setFieldValue,
    values: { invoiceItems: correctItems, originalInvoice },
  } = formikContext;
  const originalItem = originalInvoice.invoiceItems[index];
  const correctItem = correctItems[index];

  const originalformikValueKey = `originalInvoice.invoiceItems[${index}]`;
  const correctformikValueKey = `invoiceItems[${index}]`;

  useEffect(() => {
    if (!originalItem) return;
    const brutto = calculateBrutto(
      originalItem.nettoUnitPrice,
      originalItem.vat,
      originalItem.quantity
    );
    setFieldValue(`${originalformikValueKey}.bruttoSummaryPrice`, brutto);
    const bruttoAfterCorrect = calculateBrutto(
      correctItem.nettoUnitPrice,
      correctItem.vat,
      correctItem.quantity
    );
    setFieldValue(
      `${correctformikValueKey}.bruttoSummaryPrice`,
      bruttoAfterCorrect
    );
  }, []);

  const handleVatChange = (e) => {
    const vat = sanitizeQuantity(e.target.value);
    const brutto = calculateBrutto(
      correctItem.nettoUnitPrice,
      vat,
      correctItem.quantity
    );
    setFieldValue(`${correctformikValueKey}.vat`, vat);
    setFieldValue(`${correctformikValueKey}.bruttoSummaryPrice`, brutto);
  };

  const handleNettoChange = (e) => {
    const netto = sanitizeQuantity(e.target.value);
    const brutto = calculateBrutto(
      netto,
      correctItem.vat,
      correctItem.quantity
    );
    setFieldValue(`${correctformikValueKey}.nettoUnitPrice`, netto);
    setFieldValue(`${correctformikValueKey}.bruttoSummaryPrice`, brutto);
  };

  const handleBruttoChange = (e) => {
    const brutto = sanitizeQuantity(e.target.value);
    const netto = calculateNetto(brutto, correctItem.vat, correctItem.quantity);
    setFieldValue(`${correctformikValueKey}.bruttoSummaryPrice`, brutto);
    setFieldValue(`${correctformikValueKey}.nettoUnitPrice`, netto);
  };

  const handleQuantityChange = (e) => {
    const quantity = sanitizeQuantity(e.target.value);
    const brutto = calculateBrutto(
      correctItem.nettoUnitPrice,
      correctItem.vat,
      quantity
    );
    setFieldValue(`${correctformikValueKey}.quantity`, quantity);
    setFieldValue(`${correctformikValueKey}.bruttoSummaryPrice`, brutto);
  };

  // const RemoveItem = (index) => {
  //   setFieldValue(
  //     "invoiceItems",
  //     invoiceItems.filter((_, i) => i !== index)
  //   );
  // };

  return (
    <>
      <div className="item">
        <div className="product-name">{originalItem.product.name}</div>
        <TextInput
          disabled
          id={`${originalformikValueKey}.quantity`}
          placeholder="Ilość"
          onChange={(e) => handleQuantityChange(e)}
        />
        <div className="unit-name">{originalItem.product.unitName}</div>

        <TextInput
          disabled
          id={`${originalformikValueKey}.nettoUnitPrice`}
          placeholder="Cena sztuki netto"
          onChange={(e) => handleNettoChange(e)}
        />
        <TextInput
          disabled
          id={`${originalformikValueKey}.vat`}
          placeholder="VAT"
          onChange={(e) => handleVatChange(e)}
        />
        <TextInput
          disabled
          id={`${originalformikValueKey}.bruttoSummaryPrice`}
          placeholder="Wartość Brutto"
          onChange={(e) => handleBruttoChange(e)}
        />
        {/* <Button
        shape="circle"
        variant="solid"
        color="red"
        onClick={() => RemoveItem(index)}
      >
        <MinusOutlined />
      </Button> */}
      </div>
      <div className="item correct-item">
        <div className="product-name hide">{correctItem.product.name}</div>
        <TextInput
          // type="decimal"
          id={`${correctformikValueKey}.quantity`}
          placeholder="Ilość"
          onChange={(e) => handleQuantityChange(e)}
        />
        <div className="unit-name hide">{correctItem.product.unitName}</div>

        <TextInput
          // type="decimal"
          id={`${correctformikValueKey}.nettoUnitPrice`}
          placeholder="Cena sztuki netto"
          onChange={(e) => handleNettoChange(e)}
        />
        <TextInput
          id={`${correctformikValueKey}.vat`}
          placeholder="VAT"
          onChange={(e) => handleVatChange(e)}
        />
        <TextInput
          id={`${correctformikValueKey}.bruttoSummaryPrice`}
          placeholder="Wartość Brutto"
          onChange={(e) => handleBruttoChange(e)}
        />
        {/* <Button
        shape="circle"
        variant="solid"
        color="red"
        onClick={() => RemoveItem(index)}
      >
        <MinusOutlined />
      </Button> */}
      </div>
    </>
  );
};

export default ItemCorrectEdit;
