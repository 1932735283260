import { initialValues } from "./initialValues";
import { getCategoryById, postCategory, deleteCategory } from "connector";
import FormikWrapper from "components/FormikWrapper";
import TextInput from "components/FormikWrapper/FormControls/Input";
import TextareaInput from "components/FormikWrapper/FormControls/Textarea";
import "./CategoryEditForm.scss";
import ButtonsFooter from "components/FormikWrapper/FormControls/FooterButtons";
import FetchWrapper from "components/FetchWrapper";
import DeleteRowButton from "components/DeleteRowButton";

const CategoryEditForm = ({ data: category, onClose }) => {
  const onSubmit = async (values) => {
    await postCategory({ data: values }).then(() => {
      onClose();
    });
  };

  return (
    <div className="CategoryEditForm-Module">
      <FormikWrapper
        initialValues={initialValues(category)}
        onSubmit={onSubmit}
      >
        <div className="CategoryEditForm-module">
          <TextInput id="name" placeholder="Nazwa" />
          <TextareaInput id="description" placeholder="Opis" />

          <ButtonsFooter onClose={onClose}>
            {category.id && (
              <DeleteRowButton
                id={category.id}
                connector={deleteCategory}
                onAfterRemove={onClose}
              />
            )}
          </ButtonsFooter>
        </div>
      </FormikWrapper>
    </div>
  );
};

const CategoryEditFormWrapper = ({ id, onClose }) => (
  <FetchWrapper
    onClose={onClose}
    component={CategoryEditForm}
    id={id}
    connector={() => getCategoryById({ id })}
    errorMessage="Nie można pobrać danych kategorii."
  />
);

export default CategoryEditFormWrapper;
