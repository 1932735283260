export const initialValues = (payload) => ({
  firstName: "",
  lastName: "",
  login: "",
  password: "",
  employeeCode: "",
  registrationNumber: "",
  phoneNumber: "",
  email: "",
  status: 1,
  ...payload,
});
