import { Card } from "antd";
import dayjs from "dayjs";
import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import Popup2 from "components/Popup2";
import RouteEditForm from "modules/RouteEditForm";

const RoutesCard = ({ routes, currentDate, refetch }) => {
  const dayjsDate = dayjs(currentDate);
  const dayOfWeek = dayjsDate.format("dddd");
  const formattedDate = dayjsDate.format("YYYY-MM-DD");

  const dayClass = ["day"];
  if (dayOfWeek === "sobota" || dayOfWeek === "niedziela") {
    dayClass.push("weekend");
  }

  return (
    <Card
      key={currentDate}
      className={dayClass.join(" ")}
      title={`${formattedDate} ${dayOfWeek}`}
      bordered={false}
      style={{ width: "25%" }}
    >
      {routes.map((route, index) => {
        return (
          <Popup2
            key={index}
            ButtonComponent={Button}
            buttonProps={{
              className: "route-card-button",
              color: "green",
              variant: "solid",
            }}
            openButtonText={
              <>
                <div>
                  <b>{route.name}</b>
                </div>
                <div>
                  {route.driver.firstName} {route.driver.lastName}
                </div>
              </>
            }
            component={RouteEditForm}
            componentProps={{ routeId: route.id, currentDate }}
            modalProps={{ width: 930 }}
            afterClose={() => refetch()}
          />
        );
      })}
      <Popup2
        ButtonComponent={Button}
        modalProps={{ width: 930 }}
        buttonProps={{
          className: "add-route-button",
          color: "green",
          variant: "solid",
        }}
        openButtonText={<PlusOutlined />}
        component={RouteEditForm}
        componentProps={{ currentDate, routeId: "new" }}
        afterClose={() => refetch()}
      ></Popup2>
    </Card>
  );
};

export default RoutesCard;
