import dayjs from "dayjs";

export const columnsConfig = () => [
  {
    title: "Numer Karty",
    dataIndex: "wasteNumber",
    sorter: true,
  },
  {
    title: "Ilość",
    dataIndex: "quantity",
  },
  {
    title: "Data odebrania",
    render: (item) => {
      return dayjs(item.transferDate).format("DD.MM.YYYY");
    },
  },
  {
    title: "Klient",
    render: (item) => {
      return item.client.name;
    },
  },
  {
    title: "Lokalizacja",
    render: (item) => {
      return item.location.name;
    },
  },
  {
    title: "Kierowca",
    render: (item) => {
      return `${item.driver.firstName} ${item.driver.lastName}`;
    },
  },
  {
    title: "Notatka",
    dataIndex: "notes",
  },
];
