import SuppliersTable from "modules/SuppliersTable";

const Suppliers = () => {
  return (
    <div>
      <SuppliersTable />
    </div>
  )
}

export default Suppliers;
