import { useFormikContext } from "formik";
import { useState } from "react";
import dayjs from "dayjs";

import FormikWrapper from "components/FormikWrapper";
import { getRouteById } from "connector";
import { initialValues } from "./initialValues";
import TextInput from "components/FormikWrapper/FormControls/Input";
import FetchListInput from "components/FormikWrapper/FormControls/FetchList";
import HeaderCard from "components/HeaderCard";
import DateInput from "components/FormikWrapper/FormControls/Date";
import { PlusOutlined } from "@ant-design/icons";
import "./RouteEditForm.scss";
import Popup2 from "components/Popup2";
import LocationsTable from "modules/LocationsTable";
import LocationOrderForm from "./LocationOrderForm";
import Divider from "components/Divider";
import { postRoute, getDirtyOilWithSpecialPrice, deleteRoute } from "connector";
import FetchWrapper from "components/FetchWrapper";
import ButtonsFooter from "components/FormikWrapper/FormControls/FooterButtons";
import LocationSorter from "./LocationSorter";
import { SwapOutlined } from "@ant-design/icons";
import Alert from "antd/lib/alert";
import { RadiusSuccesButton, SuccessButton } from "components/Buttons";
import DeleteRowButton from "components/DeleteRowButton";
import Surplus from "./Surplus";

const LocationsPopup = ({ onClose, ...props }) => {
  const { setFieldValue, values } = useFormikContext();

  const handleAdd = async (data) => {
    const dirtyOil = await getDirtyOilWithSpecialPrice({ locationId: data.id });

    setFieldValue("ordersConfig", [
      ...values.ordersConfig,
      {
        locationId: data.id,
        products: [
          {
            productId: dirtyOil.id,
            quantity: 0,
            price: dirtyOil.specialPrice
              ? dirtyOil.specialPrice
              : dirtyOil.nettoUnitPrice,
          },
        ],
      },
    ]);
  };

  return (
    <LocationsTable
      onSelect={(data) => {
        handleAdd(data);
        onClose();
      }}
      {...props}
    />
  );
};

const Form = ({ onClose }) => {
  const [isDuplicate, setIsDuplicate] = useState(false);

  const {
    values: { ordersConfig, id, name, driverId, surplus, invoices },
    setFieldValue,
    setValues,
  } = useFormikContext();

  const openDuplicatePopup = () => {
    setValues(
      initialValues({
        date: dayjs().add(1, "day").format("YYYY-MM-DD"),
        name,
        driverId,
        ordersConfig,
        surplus,
      })
    );
    setIsDuplicate(true);
  };

  return (
    <div className="RouteEditForm-Module">
      <HeaderCard
        title={`Szczegóły trasy ${isDuplicate ? "- duplikowanie" : ""}`}
      />
      {(!isDuplicate && invoices?.length && (
        <Alert message="Ta trasa ma już przypisane faktury!" type="error" />
      )) ||
        ""}
      <div className="general-container">
        <FetchListInput configKey="drivers" />
        <DateInput id="date" placeholder="Data" />
        <TextInput id="name" placeholder="Nazwa" />
      </div>
      {/* <div className="general-container"> */}
      <Divider>Naddatek</Divider>
      <Surplus />
      {/* </div> */}
      <div className="locations-container">
        <Divider>Punkty dostaw</Divider>
        <HeaderCard
          children={
            <>
              <SuccessButton onClick={openDuplicatePopup}>
                Duplikuj
              </SuccessButton>
              <Popup2
                openButtonText={<SwapOutlined />}
                ButtonComponent={RadiusSuccesButton}
                componentProps={{ ordersConfig, setFieldValue }}
                component={LocationSorter}
              />
            </>
          }
        />

        {ordersConfig.map(({ locationId }, index) => (
          <div key={locationId}>
            <LocationOrderForm locationId={locationId} index={index} />
          </div>
        ))}
      </div>

      <Popup2
        openButtonText={<PlusOutlined />}
        ButtonComponent={RadiusSuccesButton}
        component={LocationsPopup}
        modalProps={{ width: 930 }}
      />
      {(!isDuplicate && invoices?.length && (
        <Alert message="Ta trasa ma już przypisane faktury!" type="error" />
      )) ||
        ""}
      <ButtonsFooter onClose={onClose}>
        {id && (
          <DeleteRowButton
            id={id}
            connector={deleteRoute}
            onAfterRemove={onClose}
          />
        )}
      </ButtonsFooter>
    </div>
  );
};

const RouteEditForm = ({ data, onClose }) => {
  return (
    <div>
      <FormikWrapper
        initialValues={initialValues(data)}
        onSubmit={async (data) => {
          await postRoute({
            data: {
              ...data,
              date: dayjs(data.date).format("YYYY-MM-DD"),
            },
          });
          onClose();
        }}
      >
        <Form onClose={onClose} />
      </FormikWrapper>
    </div>
  );
};

const RouteEditFormWrapper = ({ routeId, onClose }) => {
  return (
    <FetchWrapper
      onClose={onClose}
      component={RouteEditForm}
      id={routeId}
      connector={() => getRouteById({ id: routeId })}
      errorMessage="Nie można pobrać danych trasy."
    />
  );
};

export default RouteEditFormWrapper;
