import Header from "layout/Header/Header";
import { Routes, Route, Navigate } from "react-router-dom";
import Clients from "pages/Clients";
import ClientDetails from "pages/Clients/ClientDetails";
import Warehouse from "pages/Warehouse";
import RoutesPage from "pages/Routes";
import Invoices from "pages/Invoices";
import InvoiceDetails from "pages/Invoices/InvoiceDetails";
import WasteCards from "pages/WasteCards";
import Drivers from "pages/Drivers";
import Settings from "pages/Settings";
import "./Layout.scss";

const Layout = () => {
  return (
    <div>
      <Header />
      <div className="Layout-Content">
        <Routes>
          <Route path="/clients" element={<Clients />} />
          <Route path="/clients/:id" element={<ClientDetails />} />
          <Route path="/warehouse/*" element={<Warehouse />} />
          <Route path="/routes" element={<RoutesPage />} />
          <Route path="/invoices" element={<Invoices />} />
          <Route path="/invoices/:id" element={<InvoiceDetails />} />
          <Route path="/waste-cards" element={<WasteCards />} />
          <Route path="/drivers" element={<Drivers />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="*" element={<Navigate to="/clients" replace />} />
        </Routes>
      </div>
    </div>
  );
};

export default Layout;
