import { useFormikContext } from "formik";
import ItemCorrectEdit from "./ItemCorrectEdit";

const CorrectionItems = () => {
  const { values } = useFormikContext();
  let { invoiceItems } = values;
  const originalItems = values?.originalInvoice?.invoiceItems || [];
  const correctionItems = invoiceItems || [];

  return (
    <div className="InvoiceItemsEditForm-module">
      {originalItems.map((_, index) => (
        <>
          <ItemCorrectEdit key={index} index={index} />
        </>
      ))}
    </div>
  );
};

export default CorrectionItems;
