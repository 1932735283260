import axios from "axios";
import objectToQueryString from "utils/objectToQueryString";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_PATH || "http://localhost:8080",
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});

apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("authToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      console.error("Nieautoryzowany! Wylogowanie użytkownika.");
    }
    return Promise.reject(error);
  }
);

export const apiRequest = async ({
  method,
  url,
  params = "",
  data = null,
  errorMessage,
  responseType = "json",
}) => {
  try {
    const preparedParams =
      typeof params === "string" ? params : objectToQueryString(params);

    const response = await apiClient({
      method, // Typ żądania: GET, POST, PUT, DELETE
      url: `/${url}${preparedParams}`,
      data: method !== "DELETE" ? data : undefined, // Używane w POST, PUT
      responseType,
    });
    return response.data;
  } catch (error) {
    alert(
      `${errorMessage}\n ${JSON.stringify(
        error?.response?.data?.message || "Nieznany błąd"
      )}`
    );
    throw error; // Rzuć błąd, aby obsłużyć go w miejscu wywołania
  }
};

export const apiGet = async ({
  url,
  params = "",
  errorMessage,
  responseType,
}) => {
  return apiRequest({ method: "GET", url, params, errorMessage, responseType });
};

export const apiPost = async ({ url, data, errorMessage, responseType }) => {
  return apiRequest({ method: "POST", url, data, errorMessage, responseType });
};

export const apiPut = async ({ url, data, errorMessage }) => {
  return apiRequest({ method: "PUT", url, data, errorMessage });
};

export const apiDelete = async ({ url, params = "", errorMessage }) => {
  return apiRequest({ method: "DELETE", url, params, errorMessage });
};

export default apiClient;
