import { columnsConfig } from "./config";
import { getCategories } from "connector";
import CategoryEditForm from "modules/CategoryEditForm";
import SearchForm from "./SearchForm";
import AdvancedTable2 from "components/AdwancedTable2";

const CategoriesTable = (props) => {
  return (
    <AdvancedTable2
      title="Kategorie"
      columns={columnsConfig()}
      onFetch={getCategories}
      FilterComponent={SearchForm}
      EditAddComponent={CategoryEditForm}
      {...props}
    />
  );
};

export default CategoriesTable;
