const columnsConfig = () => [
  {
    title: "Imię",
    dataIndex: "firstName",
    sorter: true,
  },
  {
    title: "Nazwisko",
    dataIndex: "lastName",
    sorter: true,
  },
  {
    title: "Login",
    dataIndex: "login",
  },
  {
    title: "Kod pracownika",
    dataIndex: "employeeCode",
    sorter: true,
  },
  {
    title: "Numer rejestracyjny",
    dataIndex: "registrationNumber",
    sorter: true,
  },
  {
    title: "Numer telefonu",
    dataIndex: "phoneNumber",
  },
  {
    title: "E-mail",
    dataIndex: "email",
  },
  {
    title: "Status",
    dataIndex: "status",
  },
];

export default columnsConfig;
