import { apiDelete, apiGet, apiPost } from "config/axios";

export const getClients = async ({ params }) => {
  const payload = await apiGet({
    url: "clients",
    params,
    errorMessage: "Nie udało się pobrać wszystkich klientów",
  });
  return payload;
};

export const getClientById = async ({ id }) => {
  const payload = await apiGet({
    url: `clients/${id}`,
    errorMessage: "Nie udało się pobrać pojedynczego klienta",
  });
  return payload;
};

export const getCeidgData = async ({ type, value }) => {
  const payload = await apiGet({
    url: `ceidg/${type}/${value}`,
    errorMessage: "Nie udało się pobrać danych z CEIDG oraz GUS",
  });
  return payload;
};

export const postClient = async ({ data }) => {
  const payload = await apiPost({
    url: "clients",
    data,
    errorMessage: "Nie udało się dodać klienta",
  });
  return payload;
};

export const deleteClient = async ({ id }) => {
  const payload = await apiDelete({
    url: `clients/${id}`,
    errorMessage: "Nie udało się usunąć klienta",
  });
  return payload;
};

export const getProducts = async ({ params }) => {
  const payload = await apiGet({
    url: "products",
    params,
    errorMessage: "Nie udało się pobrać wszystkich produktów",
  });
  return payload;
};

export const getProductById = async ({ id }) => {
  const payload = await apiGet({
    url: `products/${id}`,
    errorMessage: "Nie udało się pobrać pojedynczego produktu",
  });
  return payload;
};

export const getDirtyOilWithSpecialPrice = async ({ locationId }) => {
  const payload = await apiGet({
    url: `dirty-oil-with-special-price/${locationId}`,
    errorMessage: "Nie udało się pobrać danych olejów",
  });
  return payload;
};

export const postProduct = async ({ data }) => {
  const payload = await apiPost({
    url: "products",
    data,
    errorMessage: "Nie udało się dodać produktu",
  });
  return payload;
};

export const deleteProduct = async ({ id }) => {
  const payload = await apiDelete({
    url: `products/${id}`,
    errorMessage: "Nie udało się usunąć produktu",
  });
  return payload;
};

export const getLocations = async ({ params }) => {
  const payload = await apiGet({
    url: "locations",
    params,
    errorMessage: "Nie udało się pobrać wszystkich lokalizacji",
  });
  return payload;
};

export const getLocationById = async ({ id }) => {
  const payload = await apiGet({
    url: `locations/${id}`,
    errorMessage: "Nie udało się pobrać pojedynczej lokalizacji",
  });
  return payload;
};

export const getLocationsByClientId = async ({ params, clientId }) => {
  let preparedParams = params;
  if (clientId) {
    preparedParams += `&clientId=${clientId}`;
  }
  const payload = await apiGet({
    url: "locations",
    params: preparedParams,
    errorMessage: "Nie udało się pobrać pojedynczej lokalizacji",
  });
  return payload;
};

export const postLocation = async ({ data }) => {
  const payload = await apiPost({
    url: "locations",
    data,
    errorMessage: "Nie udało się dodać lokalizacji",
  });
  return payload;
};

export const deleteLocation = async ({ id }) => {
  const payload = await apiDelete({
    url: `locations/${id}`,
    errorMessage: "Nie udało się usunąć lokalizacji",
  });
  return payload;
};

export const getWasteCards = async ({ params }) => {
  const payload = await apiGet({
    url: "wastecards",
    params,
    errorMessage: "Nie udało się pobrać wszystkich kart odpadów",
  });
  return payload;
};

export const getWasteCardById = async ({ id }) => {
  const payload = await apiGet({
    url: `wastecards/${id}`,
    errorMessage: "Nie udało się pobrać pojedynczej karty odpadów",
  });
  return payload;
};

export const getWasteCardsByClientId = async ({ params, clientId }) => {
  const payload = await apiGet({
    url: "wastecards",
    params: params + `&clientId=${clientId}`,
    errorMessage: "Nie udało się pobrać pojedynczej karty odpadów",
  });
  return payload;
};

export const postWasteCard = async ({ data }) => {
  const payload = await apiPost({
    url: "wastecards",
    data,
    errorMessage: "Nie udało się dodać karty odpadów",
  });
  return payload;
};

export const deleteWasteCard = async ({ id }) => {
  const payload = await apiDelete({
    url: `wastecards/${id}`,
    errorMessage: "Nie udało się usunąć karty odpadów",
  });
  return payload;
};

export const getNextWasteCardNumber = async ({ driverId, transferDate }) => {
  const payload = await apiPost({
    url: "next-waste-card-number",
    data: { driverId, transferDate },
    errorMessage: "Nie udało się pobrać kolejnego numeru faktury",
  });
  return payload;
};

export const getWasteCardXLS = async ({ data }) => {
  const payload = await apiPost({
    url: "wastecards-xls",
    data,
    errorMessage: "Nie udało się pobrać pliku XLS",
    responseType: "blob",
  });
  return payload;
};

export const getWasteCardPDF = async ({ id }) => {
  const payload = await apiGet({
    url: `pdf/wastecards/${id}`,
    errorMessage: "Nie udało się pobrać pliku PDF",
    responseType: "blob",
  });
  return payload;
};

export const getDrivers = async ({ params }) => {
  const payload = await apiGet({
    url: "drivers",
    params,
    errorMessage: "Nie udało się pobrać danych kierowców",
  });
  return payload;
};

export const getDriverById = async ({ id }) => {
  const payload = await apiGet({
    url: `drivers/${id}`,
    errorMessage: "Nie udało się pobrać pojedynczego kierowcy",
  });
  return payload;
};

export const postDriver = async ({ data }) => {
  const payload = await apiPost({
    url: "drivers",
    data,
    errorMessage: "Nie udało się dodać kierowcy",
  });
  return payload;
};

export const deleteDriver = async ({ id }) => {
  const payload = await apiDelete({
    url: `drivers/${id}`,
    errorMessage: "Nie udało się usuąć kierowcy",
  });
  return payload;
};

export const getInvoices = async ({ params }) => {
  const payload = await apiGet({
    url: "invoices",
    params,
    errorMessage: "Nie udało się pobrać wszystkich faktur",
  });
  return payload;
};

export const getInvoiceById = async ({ id }) => {
  const payload = await apiGet({
    url: `invoices/${id}`,
    errorMessage: "Nie udało się pobrać pojedynczej faktury",
  });
  return payload;
};

export const getInvoiceNextNumber = async ({
  format,
  createDate,
  invoiceType,
}) => {
  const payload = await apiPost({
    url: "next-invoice-number",
    data: { format, createDate, invoiceType },
    errorMessage: "Nie udało się pobrać kolejnego numeru faktury",
  });
  return payload;
};

export const getNextInvoiceNumber = async ({ format, date }) => {
  const payload = await apiPost({
    url: "next-invoice-number",
    data: { format, date },
    errorMessage: "Nie udało się pobrać kolejnego numeru faktury",
  });
  return payload;
};

export const postInvoice = async ({ data }) => {
  const payload = await apiPost({
    url: "invoices",
    data,
    errorMessage: "Nie udało się dodać faktury",
  });
  return payload;
};

export const deleteInvoice = async ({ id }) => {
  const payload = await apiDelete({
    url: `invoices/${id}`,
    errorMessage: "Nie udało się usunąć faktury",
  });
  return payload;
};

export const getInvoicePDF = async ({ id }) => {
  const payload = await apiGet({
    url: `pdf/invoices/${id}`,
    errorMessage: "Nie udało się pobrać pliku PDF",
    responseType: "blob",
  });
  return payload;
};

export const getInvoicesRaport = async ({ type, params }) => {
  const payload = await apiGet({
    url: `invoices-raport/${type}`,
    params,
    errorMessage: "Nie udało się pobrać raportu faktur",
    responseType: "blob",
  });
  return payload;
};

export const getCategories = async ({ params }) => {
  const payload = await apiGet({
    url: "categories",
    params,
    errorMessage: "Nie udało się pobrać danych kategorii",
  });
  return payload;
};

export const getCategoryById = async ({ id }) => {
  const payload = await apiGet({
    url: `categories/${id}`,
    errorMessage: "Nie udało się pobrać pojedynczej kategorii",
  });
  return payload;
};

export const postCategory = async ({ data }) => {
  const payload = await apiPost({
    url: "categories",
    data,
    errorMessage: "Nie udało się dodać kategorii",
  });
  return payload;
};

export const deleteCategory = async ({ id }) => {
  const payload = await apiDelete({
    url: `categories/${id}`,
    errorMessage: "Nie udało się usunąć kategorii",
  });
  return payload;
};

export const getSuppliers = async ({ params }) => {
  const payload = await apiGet({
    url: "suppliers",
    params,
    errorMessage: "Nie udało się pobrać danych dostawców",
  });
  return payload;
};

export const getSupplierById = async ({ id }) => {
  const payload = await apiGet({
    url: `suppliers/${id}`,
    errorMessage: "Nie udało się pobrać pojedynczej kategorii",
  });
  return payload;
};

export const postSupplier = async ({ data }) => {
  const payload = await apiPost({
    url: "suppliers",
    data,
    errorMessage: "Nie udało się dodać dostawcy",
  });
  return payload;
};

export const deleteSupplier = async ({ id }) => {
  const payload = await apiDelete({
    url: `suppliers/${id}`,
    errorMessage: "Nie udało się usunąć dostawcy",
  });
  return payload;
};

export const routesByDates = async ({ params }) => {
  const payload = await apiGet({
    url: "routes-by-dates",
    params,
    errorMessage: "Nie udało się pobrać danych tras",
  });
  return payload;
};

export const getRouteById = async ({ id }) => {
  const payload = await apiGet({
    url: `routes/${id}`,
    errorMessage: "Nie udało się pobrać pojedynczej trasy",
  });
  return payload;
};

export const postRoute = async ({ data }) => {
  const payload = await apiPost({
    url: "routes",
    data,
    errorMessage: "Nie udało się dodać trasy",
  });
  return payload;
};

export const deleteRoute = async ({ id }) => {
  const payload = await apiDelete({
    url: `routes/${id}`,
    errorMessage: "Nie udało się usunąć trasy",
  });
  return payload;
};

export const getSettings = async ({ params }) => {
  const payload = await apiGet({
    url: "settings",
    params,
    errorMessage: "Nie udało się pobrać ustawień",
  });
  return payload;
};

export const postSetting = async (data) => {
  const payload = await apiPost({
    url: "settings",
    data,
    errorMessage: "Nie udało się dodać ustawień",
  });
  return payload;
};

export const getSpecialPricesByClientId = async ({ params, clientId }) => {
  const payload = await apiGet({
    url: "special-prices",
    params: params + `&clientId=${clientId}`,
    errorMessage: "Nie udało się pobrać cen specjalnych",
  });
  return payload;
};

export const getSpecialPriceById = async ({ id }) => {
  const payload = await apiGet({
    url: `special-prices/${id}`,
    errorMessage: "Nie udało się pobrać pojedynczej ceny specjalnej",
  });
  return payload;
};

export const postSpecialPrice = async ({ data }) => {
  const payload = await apiPost({
    url: "special-prices",
    data,
    errorMessage: "Nie udało się dodać ceny specjalnej",
  });
  return payload;
};

export const deleteSpecialPrice = async ({ id }) => {
  const payload = await apiDelete({
    url: `special-prices/${id}`,
    errorMessage: "Nie udało się usunąć ceny specjalnej",
  });
  return payload;
};
