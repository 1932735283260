export const sidebarNavConfig = [
  {
    title: "Produkty",
    path: "/warehouse/products",
  },
  {
    title: "Kategorie",
    path: "/warehouse/categories",
  },
  {
    title: "Dostawcy",
    path: "/warehouse/suppliers",
  },
];
