import { getSpecialPricesByClientId } from "connector";
import objectToQueryString from "utils/objectToQueryString";

const getSpecialProductPrice = async ({
  clientId,
  productId,
  existingPrice,
}) => {
  const specialProductPrice = await getSpecialPricesByClientId({
    clientId: clientId,
    params: objectToQueryString({ filters: { productId } }),
  });

  if (specialProductPrice?.data?.length > 0) {
    return specialProductPrice.data[0].specialPrice;
  } else {
    return existingPrice;
  }
};

export default getSpecialProductPrice;
