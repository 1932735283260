import React, { useEffect, useState } from "react";
import objectToQueryString from "utils/objectToQueryString";
import { Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Popup2 from "components/Popup2";
import HeaderCard from "components/HeaderCard";
import "./AdvancedTable.scss";
import { useNavigate } from "react-router-dom";

import { PrimaryButton, SuccessButton } from "components/Buttons";

const AdvancedTable2 = ({
  columns,
  onFetch,
  FilterComponent,
  title,
  navigatePath,
  onSelect,
  EditAddComponent,
  render,
  ...props
}) => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 20,
    },
  });
  const fetchData = () => {
    const params = objectToQueryString(tableParams);
    if (loading) return;
    setLoading(true);
    onFetch({ params }).then((payload) => {
      setData(payload.data || []);
      setLoading(false);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          ...payload.pagination,
        },
      });
    });
  };

  useEffect(fetchData, [
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
    tableParams?.sortOrder,
    tableParams?.sortField,
    JSON.stringify(tableParams.filters),
  ]);
  const handleTableChange = (pagination, _, sorter) => {
    setTableParams({
      pagination,
      filters: tableParams.filters,
      sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
      sortField: Array.isArray(sorter) ? undefined : sorter.field,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const onFilter = (values) => {
    setTableParams({
      ...tableParams,
      filters: values,
    });
  };

  const onClear = () => {
    setTableParams({
      ...tableParams,
      filters: {},
    });
  };

  if (render) {
    if (typeof render === "function") {
      columns = [
        ...columns,
        {
          title: "",
          key: "custom-render",
          render: (data) => render(data, fetchData),
        },
      ];
    } else if (Array.isArray(render)) {
      columns = [...columns, ...render];
    }
  }

  columns = [
    ...columns,
    {
      title: "Akcje",
      key: "actions",
      render: (record) =>
        onSelect ? (
          <PrimaryButton onClick={() => onSelect(record)}>
            Wybierz
          </PrimaryButton>
        ) : navigatePath ? (
          <PrimaryButton
            onClick={() => navigate(`${navigatePath}${record.id}`)}
          >
            Otwórz
          </PrimaryButton>
        ) : EditAddComponent ? (
          <Popup2
            componentProps={{ ...record }}
            component={EditAddComponent}
            openButtonText="Otwórz"
            modalProps={{ width: 930 }}
            afterClose={fetchData}
          />
        ) : null,
    },
  ];

  return (
    <div className="AdvancedTable-Module">
      <HeaderCard title={title || ""}>
        {props.headerChildren}
        {EditAddComponent && !onSelect && (
          <Popup2
            componentProps={{ id: "new", ...props }}
            ButtonComponent={SuccessButton}
            component={EditAddComponent}
            openButtonText="Dodaj"
            modalProps={{ width: 930 }}
            afterClose={fetchData}
          />
        )}
        <Popup2
          buttonProps={{
            icon: <SearchOutlined />,
          }}
          openButtonText="Filtruj"
          componentProps={{
            onSearch: onFilter,
            onClear,
            initialValues: tableParams.filters,
          }}
          component={FilterComponent}
        />
      </HeaderCard>
      <Table
        size="small"
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={{ ...tableParams.pagination, position: ["bottomCenter"] }}
        loading={loading}
        onChange={handleTableChange}
      />
    </div>
  );
};
export default AdvancedTable2;
