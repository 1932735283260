import { SuccessButton, WarningButton } from "components/Buttons";
import "../FormikWrapper.scss";

const ButtonsFooter = ({ onClose, children, hideSubmit }) => (
  <div className="button-container button-footer-container">
    {!hideSubmit && <SuccessButton htmlType="submit">Zapisz</SuccessButton>}
    <WarningButton htmlType="reset" onClick={onClose}>
      Anuluj
    </WarningButton>
    {children}
  </div>
);

export default ButtonsFooter;
