import { useParams } from "react-router-dom";
import ClientEditForm from "modules/ClientEditForm";
import LocationsTable from "modules/LocationsTable";
import WasteCardsTable from "modules/WasteCardsTable";
import Divider from "components/Divider";
import SpecialPricesTable from "modules/SpecialPricesTable";

const ClientDetails = () => {
  const { id } = useParams();

  return (
    <div>
      <ClientEditForm id={id} />
      {id !== "new" && (
        <>
          <Divider>Lokale</Divider>
          <LocationsTable clientId={id} />
          <Divider>Karty odpadów</Divider>
          <WasteCardsTable clientId={id} />
          <Divider>Ceny specjalne</Divider>
          <SpecialPricesTable clientId={id} />
        </>
      )}
    </div>
  );
};

export default ClientDetails;
