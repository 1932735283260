import { useEffect, useState, useCallback } from "react";
import LoadingSpiner from "components/LoadingSpiner";

const FetchWrapper = ({ component, id, connector, errorMessage, ...props }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);

  const Component = component;

  const fetchElement = useCallback(async () => {
    if (id !== "new") {
      setIsLoading(true);
      try {
        const response = await connector();
        setData(response);
      } catch (error) {
        console.error(errorMessage, error);
      } finally {
        setIsLoading(false);
      }
    } else {
      setData({});
      setIsLoading(false);
    }
  }, [id, connector, errorMessage, isLoading]);

  useEffect(() => {
    fetchElement();
  }, []);

  if (isLoading || !data) {
    return <LoadingSpiner />;
  }

  return <Component data={data} refetch={fetchElement} {...props} />;
};

export default FetchWrapper;
