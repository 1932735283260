export const navConfig = [
  {
    title: "Klienci",
    path: "/clients",
  },
  {
    title: "Magazyn",
    path: "/warehouse",
  },
  {
    title: "Trasy dostaw",
    path: "/routes",
  },
  {
    title: "Faktury",
    path: "/invoices",
  },
  {
    title: "Karty odpadów",
    path: "/waste-cards",
  },
  {
    title: "Kierowcy",
    path: "/drivers",
  },
  {
    title: "Ustawienia",
    path: "/settings",
  },
];
