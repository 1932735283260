import FormikWrapper from "components/FormikWrapper";
import TextInput from "components/FormikWrapper/FormControls/Input";
import { Button } from "antd";
import * as Yup from "yup";

const SearchForm = ({ initialValues = {}, onSearch, onClear, onClose }) => {
  const validationSchema = Yup.object({
    regon: Yup.string()
      .matches(/^\d+$/, 'REGON może zawierać tylko cyfry')
      .max(14, 'REGON może mieć maksymalnie 14 znaków'),
    nip: Yup.string()
      .matches(/^\d+$/, 'NIP może zawierać tylko cyfry')
      .max(10, 'NIP może mieć maksymalnie 10 znaków'),
  });

  return (
    <div className="SearchForm-Module">
      <h2>Filtr klientów</h2>
      <FormikWrapper
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSearch}
      >
        <TextInput id="name" placeholder="Nazwa Firmy" />
        <TextInput id="clientName" placeholder="Nazwa klienta" />
        <TextInput id="nip" placeholder="NIP" />
        <TextInput id="regon" placeholder="REGON" />
        <TextInput id="city" placeholder="Miasto" />
        <TextInput id="address" placeholder="Adres" />
        <TextInput id="locationName" placeholder="Nazwa lokalizacji" />
        <TextInput id="locationAddress" placeholder="Adres lokalizacji" />
        <TextInput id="locationCity" placeholder="Miasto lokalizacji" />

        <div className="button-container">
          <Button
            htmlType="submit"
            color="green"
            variant="solid"
            onClick={(values) => {
              onClose();
            }}
          >
            Filtruj
          </Button>
          <Button
            htmlType="reset"
            onClick={(value) => {
              onClear(value);
              onClose();
            }}
            color="red"
            variant="solid"
          >
            Wyczyść
          </Button>
          <Button onClick={onClose} color="yellow" variant="solid">
            Anuluj
          </Button>
        </div>
      </FormikWrapper>
    </div>
  );
};

export default SearchForm;
