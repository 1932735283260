export const columnsConfig = () => [
  {
    title: "Nazwa",
    dataIndex: "name",
    sorter: true,
    width: "20%",
  },
  {
    title: "Opis",
    dataIndex: "description",
  },
];
