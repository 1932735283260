import { useFormikContext } from "formik";
import { useEffect, useMemo } from "react";
import { calculateSummaryNetto, calculateSummaryBrutto } from "utils/cashUtils";
const Summary = () => {
  const formikContext = useFormikContext();
  const { values } = formikContext;

  const originalItems = values?.originalInvoice?.invoiceItems || [];
  const correctionItems = values?.invoiceItems || [];

  const summaryBrutton = useMemo(
    () => calculateSummaryBrutto(originalItems),
    [values?.originalInvoice]
  );
  const summaryNetto = useMemo(
    () => calculateSummaryNetto(originalItems),
    [values?.originalInvoice]
  );

  const correctSummaryBrutton = useMemo(
    () => calculateSummaryBrutto(correctionItems),
    [correctionItems]
  );
  const correctSummaryNetto = useMemo(
    () => calculateSummaryNetto(correctionItems),
    [correctionItems]
  );

  useEffect(() => {
    formikContext.setFieldValue("totalBruttoPrice", summaryBrutton);
  }, [summaryBrutton]);

  return (
    <div className="Summary-invoice-module">
      <div>
        Brutto: <span className="brutto">{summaryBrutton}</span>
        PLN
      </div>
      <div>
        Netto: <span className="netto">{summaryNetto}</span>
        PLN
      </div>

      <div>
        Brutto po korekcie:{" "}
        <span className="brutto">{correctSummaryBrutton}</span>
        PLN
      </div>
      <div>
        Netto po korekcie: <span className="netto">{correctSummaryNetto}</span>
        PLN
      </div>
    </div>
  );
};

export default Summary;
