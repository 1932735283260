import Popup2 from "components/Popup2";
import { PlusOutlined } from "@ant-design/icons";
import { RadiusSuccesButton } from "components/Buttons";
import WasteCardsTable from "modules/WasteCardsTable";
import { columnsConfig } from "./config";
import { Table } from "antd";
import { useFormikContext } from "formik";

const AttachedWasteCards = () => {
  const { values, setFieldValue } = useFormikContext();
  const { wasteCards = [], id: invoiceId, clientId } = values;

  const addWasteCards = (newCard) => {
    const updatedWasteCards = [...wasteCards, newCard];
    setFieldValue("wasteCards", updatedWasteCards);
  };

  const removeWasteCard = (id) => {
    const updatedWasteCards = wasteCards.filter((card) => card.id !== id);
    setFieldValue("wasteCards", updatedWasteCards);
  };

  return (
    <div className="AttachedWasteCards-Module">
      <div>
        <Table
          size="small"
          columns={columnsConfig({ removeWasteCard })}
          rowKey={(record) => record.id}
          dataSource={wasteCards}
        />

        <Popup2
          openButtonText={<PlusOutlined />}
          ButtonComponent={RadiusSuccesButton}
          component={WasteCardsTable}
          modalProps={{ width: 930 }}
          componentProps={{
            invoiceId,
            clientId,
            onSelect: addWasteCards,
          }}
        />
      </div>
    </div>
  );
};

export default AttachedWasteCards;
