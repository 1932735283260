import { useFormikContext } from "formik";
import { getLocationById } from "connector";
import { Button } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import ProductsOrder from "./ProductsOrder";
import FetchWrapper from "components/FetchWrapper";
import { PlusOutlined } from "@ant-design/icons";
import Popup2 from "components/Popup2";
import ProductsTable from "modules/ProductsTable";
import updateBySpecialProductPrice from "utils/updateBySpecialProductPrice";
import TextareaInput from "components/FormikWrapper/FormControls/Textarea";

const LocationOrderForm = ({
  data: location,
  locationId,
  index: locationIndex,
  onClose,
}) => {
  const { values, setFieldValue } = useFormikContext();
  const locationConig = values.ordersConfig[locationIndex];

  const RemoveItem = () => {
    const newOrdersConfig = values.ordersConfig.filter(
      (location) => location.locationId != locationId
    );
    setFieldValue("ordersConfig", newOrdersConfig);
  };

  const handleAdd = async (data) => {
    const productId = data.id;

    data.nettoUnitPrice = await updateBySpecialProductPrice({
      clientId: location.clientId,
      productId,
      existingPrice: data.nettoUnitPrice,
    });

    const product = {
      productId,
      price: data.nettoUnitPrice,
      quantity: 1,
    };
    const newProducts = [...locationConig.products, product];
    const newOrdersConfig = values.ordersConfig.map((location, index) =>
      index === locationIndex
        ? { ...location, products: newProducts }
        : location
    );
    setFieldValue("ordersConfig", newOrdersConfig);
  };

  return (
    <div className="LocationOrderForm-module" key={locationId}>
      <Button
        shape="circle"
        variant="solid"
        color="red"
        onClick={() => RemoveItem()}
      >
        <MinusOutlined />
      </Button>
      <div className="location-order-info">
        <h4>{location.name}</h4>
        <p>{location.city}</p>
        <p>{location.address}</p>
        <TextareaInput id={`ordersConfig[${locationIndex}].additionalInfo`} />
      </div>
      <div className="location-order-products">
        {locationConig.products.map((orderProduct, productIndex) => (
          <div key={productIndex}>
            <ProductsOrder
              orderProduct={orderProduct}
              productIndex={productIndex}
              locationIndex={locationIndex}
            />
          </div>
        ))}
        <Popup2
          buttonProps={{ type: "primary", color: "green", variant: "solid" }}
          openButtonText={<PlusOutlined />}
          modalProps={{ width: 930 }}
          component={({ onClose }) => (
            <ProductsTable
              onSelect={(data) => {
                handleAdd(data);
                onClose();
              }}
              clientId={location.clientId}
            />
          )}
        />
      </div>
    </div>
  );
};

const LocationOrderFormWrapper = (props) => (
  <FetchWrapper
    onClose={props.onClose}
    component={LocationOrderForm}
    connector={() => getLocationById({ id: props.locationId })}
    errorMessage="Pobieranie lokalizacji w formularzu trasy nie powiodło się"
    {...props}
  />
);

export default LocationOrderFormWrapper;
