import { useFormikContext, getIn } from "formik";
import { Checkbox as AntCheckbox } from "antd";

const Checkbox = ({ id, placeholder, wrapperStyle, ...props }) => {
  const formikContext = useFormikContext();
  const checked = !!getIn(formikContext.values, id);

  const handleChange = (e) => {
    formikContext.setFieldValue(id, e.target.checked);
  };

  return (
    <div className="checkbox-input-wrapper" style={wrapperStyle}>
      <AntCheckbox onChange={handleChange} checked={checked} {...props} />
      <label htmlFor={id}>{placeholder}</label>
      {formikContext.errors[id] && (
        <span className="validation-error-description">
          {formikContext.errors[id]}
        </span>
      )}
    </div>
  );
};

export default Checkbox;
