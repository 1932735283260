export const initialValues = (payload) => ({
  clientId: "",
  name: "",
  address: "",
  postalCode: "",
  city: "",
  country: "",
  phone: "",
  email: "",
  openingTime: "",
  closingTime: "",
  additionalInfo: "",
  ...payload,
});
