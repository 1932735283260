import React from "react";
import { TimePicker } from "antd";
import { useFormikContext, getIn } from "formik";
import dayjs from "dayjs";

const TimeInput = ({ id, placeholder }) => {
  const formikContext = useFormikContext();

  const value = getIn(formikContext.values, id)
    ? dayjs(getIn(formikContext.values, id), "H:mm:ss").isValid()
      ? dayjs(getIn(formikContext.values, id), "H:mm:ss")
      : dayjs(getIn(formikContext.values, id), "H:mm")
    : null;

  const handleChange = (time) => {
    formikContext.setFieldValue(id, time ? dayjs(time).format("H:mm") : null);
  };

  return (
    <div className="time-input-wrapper">
      <label htmlFor={id}>{placeholder}</label>
      <TimePicker
        onChange={handleChange}
        minuteStep={5}
        hourStep={1}
        value={value}
        format="H:mm"
      />
      {formikContext.errors[id] && (
        <span className="validation-error-description">
          {formikContext.errors[id]}
        </span>
      )}
    </div>
  );
};

export default TimeInput;
