import TextInput from "components/FormikWrapper/FormControls/Input";
import { useFormikContext } from "formik";
import { Button } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import FetchListInput from "components/FormikWrapper/FormControls/FetchList";

const ProductsOrder = ({ productIndex, locationIndex }) => {
  const { values, setFieldValue } = useFormikContext();
  const formikValueKey = `ordersConfig[${locationIndex}].products[${productIndex}]`;

  const RemoveItem = () => {
    const newProducts = values.ordersConfig[locationIndex].products.filter(
      (_, index) => index != productIndex
    );
    setFieldValue(`ordersConfig[${locationIndex}].products`, newProducts);
  };

  return (
    <div className="ProductsOrder-Module">
      <FetchListInput
        configKey="products"
        configProps={{ id: `${formikValueKey}.productId` }}
      />
      <TextInput id={`${formikValueKey}.price`} placeholder="Cena" />
      <TextInput id={`${formikValueKey}.quantity`} placeholder="Ilość" />
      <Button
        className="remove-button"
        shape="circle"
        variant="solid"
        color="red"
        onClick={() => RemoveItem()}
      >
        <MinusOutlined />
      </Button>
    </div>
  );
};

export default ProductsOrder;
