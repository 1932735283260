import dayjs from "dayjs";
import "./DateSelector.scss";

const DateSelector = ({ startDate, setStartDate, daysCount }) => {
  const start = dayjs(startDate);
  const end = start.add(daysCount - 1, "day");

  const handleChange = (direction) => {
    const newStart =
      direction === "prev"
        ? start.subtract(daysCount, "day")
        : start.add(daysCount, "day");
    setStartDate(newStart.format("YYYY-MM-DD"));
  };

  return (
    <div className="DateSelector-Component">
      <button onClick={() => handleChange("prev")}>{"<"}</button>
      <strong>{`${start.format("YYYY-MM-DD")} - ${end.format(
        "YYYY-MM-DD"
      )}`}</strong>
      <button onClick={() => handleChange("next")}>{">"}</button>
    </div>
  );
};

export default DateSelector;
