import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  clientId: Yup.string().required("Klient jest wymagany"),

  name: Yup.string().required("Nazwa lokalizacji jest wymagana"),

  address: Yup.string().required("Adres jest wymagany"),

  city: Yup.string().required("Miasto jest wymagane"),

  postalCode: Yup.string()
    .matches(/^\d{2}-\d{3}$/, "Kod pocztowy musi być w formacie XX-XXX")
    .required("Kod pocztowy jest wymagany"),

  country: Yup.string().notRequired(),

  phone: Yup.string()
    .matches(/^\d{7,15}$/, "Numer telefonu musi zawierać od 7 do 15 cyfr")
    .notRequired(),

  email: Yup.string().email("Nieprawidłowy format adresu e-mail").notRequired(),

  openingTime: Yup.string().notRequired(),

  closingTime: Yup.string().notRequired(),

  additionalInfo: Yup.string()
    .max(500, "Dodatkowe informacje nie mogą przekraczać 500 znaków")
    .notRequired(),
});

export default validationSchema;
