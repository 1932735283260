import { useAuth } from "providers/AuthProvider";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import FormikWrapper from "components/FormikWrapper";
import TextInput from "components/FormikWrapper/FormControls/Input";
import * as Yup from "yup";
import "./Login.scss";

const Login = () => {
  const { login: signIn } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const validationSchema = Yup.object({
    login: Yup.string().required("Wprowadź nazwę użytkownika!"),
    password: Yup.string().required("Wprowadź hasło!"),
  });

  const onSubmit = async (values) => {
    const { login, password } = values;
    const result = await signIn(login, password);

    if(result.error){
      setError("Nieprawidłowy login lub hasło");
    }
    else if (result) {
      navigate("/clients");
    }
  };
  

  return (
    <div className="login-wrapper">
      <div className="login-container">
        <h1>Direct-desk</h1>
        <FormikWrapper
          initialValues={{ login: "", password: "" }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <div className="form-row">
            <TextInput
              id="login"
              placeholder="Nazwa użytkownika"
              showcount={false}
            />
          </div>
          <div className="form-row">
            <TextInput
              id="password"
              placeholder="Hasło"
              type="password"
              showcount={false}
            />
          </div>
          
          <Button className="submit-button" type="primary" htmlType="submit">
            Zaloguj
          </Button>
          {error && <p className="error-message">{error}</p>}
        </FormikWrapper>
      </div>
    </div>
  );
};

export default Login;
