import ProductsTable from "modules/ProductsTable";

const Products = () => {
  return (
    <div>
      <ProductsTable />
    </div>
  );
};

export default Products;
