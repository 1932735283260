export const initialValues = (payload) => ({
  name: "",
  clientName: "",
  address: "",
  city: "",
  postalCode: "",
  country: "",
  phone: "",
  mobilePhone: "",
  email: "",
  nip: null,
  regon: null,
  bankAccountNumber: "",
  isVat: 1,
  isSelfBilling: 1,
  payType: 1,
  payDelay: 14,
  ...payload,
});
