import FormikWrapper from "components/FormikWrapper";
import SelectInput from "components/FormikWrapper/FormControls/Select";
import DateInput from "components/FormikWrapper/FormControls/Date";
import { Button } from "antd";
import { useConfig } from "providers/configProvider";
import FetchListInput from "components/FormikWrapper/FormControls/FetchList";
import TextInput from "components/FormikWrapper/FormControls/Input";

const SearchForm = ({ initialValues = {}, onSearch, onClear, onClose }) => {
  const { INVOICE_TYPES_DICT, PAY_STATUSES_DICT, INVOICE_FORMATS_DICT } =
    useConfig();

  return (
    <div className="SearchForm-Module">
      <h2>Filtr faktur</h2>
      <FormikWrapper initialValues={initialValues} onSubmit={onSearch}>
        <TextInput id="number" placeholder="Numer faktury" />
        <SelectInput
          id="invoiceType"
          placeholder="Typ"
          config={INVOICE_TYPES_DICT}
        />
        <SelectInput
          id="payStatus"
          placeholder="Status płatności"
          config={PAY_STATUSES_DICT}
        />
        <DateInput id="createDate_min" placeholder="Data wystawienia od" />
        <DateInput id="createDate_max" placeholder="Data wystawienia do" />
        <SelectInput
          id="format"
          placeholder="Format"
          config={INVOICE_FORMATS_DICT}
          noNumberKey={true}
        />
        <FetchListInput configKey="clients" />

        <div className="button-container">
          <Button
            htmlType="submit"
            color="green"
            variant="solid"
            onClick={(values) => {
              onClose();
            }}
          >
            Filtruj
          </Button>
          <Button
            htmlType="reset"
            onClick={(value) => {
              onClear(value);
              onClose();
            }}
            color="red"
            variant="solid"
          >
            Wyczyść
          </Button>
          <Button onClick={onClose} color="yellow" variant="solid">
            Anuluj
          </Button>
        </div>
      </FormikWrapper>
    </div>
  );
};

export default SearchForm;
