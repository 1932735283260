import { columnsConfig } from "./config";
import SearchForm from "./SearchForm";
import { getClients } from "connector";
import ClientEditForm from "modules/ClientEditForm";
import AdvancedTable2 from "components/AdwancedTable2";

const ClientsTable = (props) => {
  return (
    <AdvancedTable2
      title="Klienci"
      columns={columnsConfig()}
      onFetch={getClients}
      FilterComponent={SearchForm}
      EditAddComponent={ClientEditForm}
      {...props}
    />
  );
};

export default ClientsTable;
