import React, { useState } from "react";
import { useFormikContext } from "formik";

import Popup2 from "components/Popup2";
import FormikWrapper from "components/FormikWrapper";
import TextInput from "components/FormikWrapper/FormControls/Input";
import SelectInput from "components/FormikWrapper/FormControls/Select";
import "./CeidgSync.scss";
import { Button } from "antd";
import { getCeidgData } from "connector";
import LoadingSpiner from "components/LoadingSpiner";

const PopupComponent = ({ onFill, onClose }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  return (
    <div className="CeidgSync-Form">
      <FormikWrapper
        initialValues={{ value: "", type: 1 }}
        onSubmit={async (values) => {
          try {
            setLoading(true);
            const payload = await getCeidgData(values);
            setData(payload);
            setLoading(false);
          } catch (error) {
            console.error("Błąd pobierania danych:", error);
          }
        }}
      >
        <div className="form-container">
          <SelectInput
            id="type"
            placeholder="Typ"
            config={{
              1: "nip",
              2: "regon",
            }}
          />
          <TextInput id="value" placeholder="Numer" />
        </div>
        <Button htmlType="submit" color="green" variant="solid">
          Pobierz
        </Button>
      </FormikWrapper>

      {loading && <LoadingSpiner />}

      {data && (
        <div className="data-display">
          <p>
            <h2>{data.companyName}</h2>
            <strong>Imię i nazwisko:</strong> {data.clientName}
            <br />
            <strong>Adres:</strong> {data.address}
            <br />
            <strong>Miasto:</strong> {data.city}
            <br />
            <strong>Kod pocztowy:</strong> {data.postalCode}
            <br />
            <strong>Kraj:</strong> {data.country}
            <br />
            <strong>NIP:</strong> {data.nip}
            <br />
            <strong>REGON:</strong> {data.regon}
            <br />
            <Button
              type="primary"
              onClick={() => {
                onFill(data);
                onClose();
              }} // Wyświetlenie danych w konsoli
            >
              Wstaw dane
            </Button>
          </p>
        </div>
      )}
    </div>
  );
};

const CeidgSync = () => {
  const formikContext = useFormikContext();

  const onFill = (data) => {
    formikContext.setFieldValue("name", data.companyName);
    formikContext.setFieldValue("clientName", data.clientName);
    formikContext.setFieldValue("city", data.city);
    formikContext.setFieldValue("address", data.address);
    formikContext.setFieldValue("nip", data.nip);
    formikContext.setFieldValue("regon", data.regon);
    formikContext.setFieldValue("country", data.country);
    formikContext.setFieldValue("postalCode", data.postalCode);
  };

  return (
    <div className="CeidgSync-Module">
      <Popup2
        buttonProps={{ type: "primary", color: "blue", variant: "solid" }}
        component={PopupComponent}
        componentProps={{ onFill }}
        openButtonText="Pobierz z CEIDG"
      />
    </div>
  );
};

export default CeidgSync;
