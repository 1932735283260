import { useFormikContext, getIn } from "formik";
import { sanitizeQuantity } from "utils/sanitizeQuantity";
import { Input } from "antd";

const TextInput = ({ id, placeholder, type, wrapperStyle, ...props }) => {
  const formikContext = useFormikContext();
  const value = getIn(formikContext.values, id);

  const handleChange = (e) => {
    if (type === "decimal") {
      const newNumber = sanitizeQuantity(e.target.value);
      formikContext.setFieldValue(id, newNumber);
      return;
    }
    formikContext.setFieldValue(id, e.target.value);
  };

  return (
    <div className="text-input-wrapper" style={wrapperStyle}>
      <label htmlFor={id}>{placeholder}</label>
      <Input
        onChange={handleChange}
        value={value}
        type={type}
        maxLength="255"
        {...props}
      />
      {formikContext.errors[id] && (
        <span className="validation-error-description">
          {formikContext.errors[id]}
        </span>
      )}
    </div>
  );
};

export default TextInput;
