import { columnsConfig } from "./config";
import { getProducts } from "connector";
import ProductEditForm from "modules/ProductEditForm";
import SearchForm from "./SearchForm";
import AdvancedTable2 from "components/AdwancedTable2";

const ProductsTable = (props) => {
  return (
    <AdvancedTable2
      title="Produkty"
      columns={columnsConfig()}
      onFetch={getProducts}
      FilterComponent={SearchForm}
      EditAddComponent={ProductEditForm}
      {...props}
    />
  );
};

export default ProductsTable;
