const objectToQueryString = (params) => {
  const { pagination, filters, sortOrder, sortField } = params;

  let query = {};

  if (pagination) {
    query = {
      current: pagination.current,
      pageSize: pagination.pageSize,
      total: pagination.total,
      ...filters,
      sortOrder:
        sortOrder === "ascend"
          ? "asc"
          : sortOrder === "descend"
          ? "desc"
          : sortOrder,
      sortField,
      ...query,
    };
  }

  if (filters) {
    query = {
      ...filters,
      ...query,
    };
  }

  return (
    "?" +
    Object.entries(query)
      .filter(
        ([_, value]) => value !== undefined && value !== null && value !== ""
      )
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&")
  );
};

export default objectToQueryString;
