import TextInput from "components/FormikWrapper/FormControls/Input";
import { useFormikContext } from "formik";
import { Button } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import FetchListInput from "components/FormikWrapper/FormControls/FetchList";
import { PlusOutlined } from "@ant-design/icons";
import Popup2 from "components/Popup2";
import ProductsTable from "modules/ProductsTable";

const Product = ({ productIndex }) => {
  const { values, setFieldValue } = useFormikContext();
  const formikValueKey = `surplus[${productIndex}]`;

  const RemoveItem = () => {
    const newProducts = values.surplus.filter(
      (_, index) => index != productIndex
    );
    setFieldValue(`surplus`, newProducts);
  };

  return (
    <div className="product">
      <FetchListInput
        configKey="products"
        configProps={{ id: `${formikValueKey}.productId` }}
      />
      <TextInput id={`${formikValueKey}.quantity`} placeholder="Ilość" />
      <Button
        className="remove-button"
        shape="circle"
        variant="solid"
        color="red"
        onClick={() => RemoveItem()}
      >
        <MinusOutlined />
      </Button>
    </div>
  );
};

const Surplus = () => {
  const {
    values: { surplus },
    setFieldValue,
  } = useFormikContext();

  const handleAdd = async (data) => {
    const productId = data.id;

    if (surplus.some((product) => product.productId === productId)) {
      alert("Produkt już jest na liście nadwyżek");
      return;
    }

    const product = {
      productId,
      quantity: 1,
      name: data.name,
      unit: data.unitName,
    };
    const newProducts = [...surplus, product];

    setFieldValue("surplus", newProducts);
  };

  return (
    <div className="Surplus-Module">
      {surplus.map((_, productIndex) => (
        <div className="product-wrapper" key={productIndex}>
          <Product productIndex={productIndex} />
        </div>
      ))}
      <Popup2
        buttonProps={{
          type: "primary",
          color: "green",
          variant: "solid",
          className: "add-button",
        }}
        openButtonText={<PlusOutlined />}
        modalProps={{ width: 930 }}
        component={({ onClose }) => (
          <ProductsTable
            onSelect={(data) => {
              handleAdd(data);
              onClose();
            }}
          />
        )}
      />
    </div>
  );
};

export default Surplus;
