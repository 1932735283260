import { Select } from "antd";
import { useFormikContext, getIn } from "formik";

const SelectInput = ({ id, placeholder, config, noNumberKey }) => {
  const formikContext = useFormikContext();
  const value = getIn(formikContext.values, id);

  const handleChange = (value) => {
    formikContext.setFieldValue(id, value);
  };

  return (
    <div className="select-input-wrapper">
      <label htmlFor={id}>{placeholder}</label>
      <Select
        showSearch
        style={{ width: "100%" }}
        placeholder={placeholder}
        optionFilterProp="children"
        onChange={handleChange}
        value={value}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {Object.entries(config).map(([key, label]) => {
          const preparedKey = noNumberKey ? key : Number(key);

          return (
            <Select.Option key={preparedKey} value={preparedKey}>
              {label}
            </Select.Option>
          );
        })}
      </Select>
      {formikContext.errors[id] && (
        <span className="validation-error-description">
          {formikContext.errors[id]}
        </span>
      )}
    </div>
  );
};

export default SelectInput;
