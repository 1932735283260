import FormikWrapper from "components/FormikWrapper";
import TextInput from "components/FormikWrapper/FormControls/Input";
import { Button } from "antd";
import SelectInput from "components/FormikWrapper/FormControls/Select";
import { useConfig } from "providers/configProvider";

const SearchForm = ({ initialValues = {}, onSearch, onClear, onClose }) => {
  const { WASTE_CARD_FORMATS_DICT } = useConfig();

  return (
    <div className="SearchForm-Module">
      <h2>Filtr klientów</h2>
      <FormikWrapper initialValues={initialValues} onSubmit={onSearch}>
        <TextInput id="wasteNumber" placeholder="Nazwa karty" />
        <SelectInput
          id="format"
          placeholder="Format"
          config={WASTE_CARD_FORMATS_DICT}
          noNumberKey={true}
        />

        <div className="button-container">
          <Button
            htmlType="submit"
            color="green"
            variant="solid"
            onClick={(values) => {
              onClose();
            }}
          >
            Filtruj
          </Button>
          <Button
            htmlType="reset"
            onClick={(value) => {
              onClear(value);
              onClose();
            }}
            color="red"
            variant="solid"
          >
            Wyczyść
          </Button>
          <Button onClick={onClose} color="yellow" variant="solid">
            Anuluj
          </Button>
        </div>
      </FormikWrapper>
    </div>
  );
};

export default SearchForm;
