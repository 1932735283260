export const sanitizeQuantity = (newQuantity) => {
  if (newQuantity === "") {
    return "";
  }

  // Jeśli użytkownik wpisuje tylko "," lub ".", zostawiamy to
  if (newQuantity.endsWith(".") || newQuantity.endsWith(",")) {
    return newQuantity;
  }

  // Zamieniamy "," na "." (standardowy separator dziesiętny)
  let preparedNumber = newQuantity.replace(",", ".");

  // Jeśli to poprawna liczba, zwracamy ją jako string (dla zachowania formatu)
  if (!isNaN(preparedNumber) && Number(preparedNumber) >= 0) {
    return preparedNumber;
  }

  // Jeśli wpisana wartość jest niepoprawna, zostawiamy ostatnią dobrą wartość
  return newQuantity;
};
