import { useFormikContext } from "formik";
import Popup2 from "components/Popup2";
import ProductsTable from "modules/ProductsTable";
import { PlusOutlined } from "@ant-design/icons";
import ItemEdit from "./ItemEdit";
import { getProductById } from "connector";
import updateBySpecialProductPrice from "utils/updateBySpecialProductPrice";
import { RadiusSuccesButton } from "components/Buttons";
import { useConfig } from "providers/configProvider";
import { vatSelector } from "../utils";

const Popup = ({ onClose }) => {
  const { setFieldValue, values } = useFormikContext();
  const GLOBAL_CONFIG = useConfig();
  const length = values.invoiceItems.length;
  const { client } = values;

  const handleAdd = async (data) => {
    const product = await getProductById({ id: data.id });

    product.nettoUnitPrice = await updateBySpecialProductPrice({
      clientId: values.clientId,
      productId: product.id,
      existingPrice: product.nettoUnitPrice,
    });

    setFieldValue(`invoiceItems[${length}]`, {
      productId: data.id,
      quantity: 1,
      nettoUnitPrice: product.nettoUnitPrice,
      vat: vatSelector({
        client,
        product,
        invoiceType: values.invoiceType,
        GLOBAL_CONFIG,
      }),
      unitName: product.unitName,
      product,
    });
    onClose();
  };

  return <ProductsTable onSelect={handleAdd} onClose={onClose} />;
};

const InvoiceItemsEditForm = () => {
  const formikContext = useFormikContext();
  let { invoiceItems } = formikContext.values;
  if (!invoiceItems) invoiceItems = [];
  return (
    <div className="InvoiceItemsEditForm-module">
      {invoiceItems.map((_, index) => (
        <ItemEdit key={index} index={index} />
      ))}

      <div>
        <Popup2
          openButtonText={<PlusOutlined />}
          buttonComponent={RadiusSuccesButton}
          buttonProps={{ shape: "circle", variant: "solid", color: "green" }}
          component={Popup}
          modalProps={{ width: 930 }}
        />
      </div>
    </div>
  );
};

export default InvoiceItemsEditForm;
