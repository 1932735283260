import { useAuth } from "providers/AuthProvider";
import LoadingSpiner from "components/LoadingSpiner";
import Layout from "layout/Layout/Layout";
import Login from "pages/Login";

const Root = () => {
  return <Layout />;
};

export default Root;
