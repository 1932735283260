import { use, useEffect } from "react";
import { Divider } from "antd";

import FormikWrapper from "components/FormikWrapper";
import TextInput from "components/FormikWrapper/FormControls/Input";
import SelectInput from "components/FormikWrapper/FormControls/Select";
import FetchListInput from "components/FormikWrapper/FormControls/FetchList";
import { initialValues } from "./initialValues";

import {
  getInvoiceById,
  getInvoiceNextNumber,
  postInvoice,
  deleteInvoice,
  getInvoicePDF,
  getClientById,
} from "connector";
import { useFormikContext } from "formik";

import { useConfig } from "providers/configProvider";
import DateInput from "components/FormikWrapper/FormControls/Date";
import TextareaInput from "components/FormikWrapper/FormControls/Textarea";
import InvoiceItemsEditForm from "./InvoiceItemsEditForm";
import Summary from "./InvoiceItemsEditForm/summary";
import ButtonsFooter from "components/FormikWrapper/FormControls/FooterButtons";
import dayjs from "dayjs";
import { getInvoiceFormat } from "./utils";
import FetchWrapper from "components/FetchWrapper";
import DeleteRowButton from "components/DeleteRowButton";
import "./InvoiceEditForm.scss";
import HeaderCard from "components/HeaderCard";
import { PrimaryButton, SuccessButton } from "components/Buttons";
import CorrectionItems from "./CorrectionItems";
import CorrectSummary from "./CorrectionItems/summary";
import Popup2 from "components/Popup2";
import AttachedWasteCards from "./AttachedWasteCards";
import { getPaymentDate } from "selectors/invoice";
import Checkbox from "components/FormikWrapper/FormControls/Checkbox";

const InitForm = () => {
  const { INVOICE_TYPES_DICT } = useConfig();
  const {
    setFieldValue,
    values: { isDriver },
  } = useFormikContext();

  useEffect(() => {
    if (!isDriver) {
      setFieldValue("driverId", null);
    }
  }, [isDriver]);

  return (
    <>
      <SelectInput
        id="invoiceType"
        placeholder="Typ faktury"
        config={INVOICE_TYPES_DICT}
      />
      <FetchListInput configKey="clients" />
      <FetchListInput configKey="locations" />
      {isDriver && <FetchListInput configKey="drivers" />}
      <Checkbox id="isDriver" placeholder="Faktura kierowcy" />
    </>
  );
};

const Form = ({ onClose }) => {
  const formikContext = useFormikContext();
  const { values, setFieldValue, setValues } = formikContext;
  const {
    PAY_STATUSES,
    PAY_STATUSES_DICT,
    INVOICE_TYPES,
    INVOICE_TYPES_DICT,
    DRIVER_CODES_DICT,
    PAYMENTS_TYPES_DICT,
  } = useConfig();

  useEffect(() => {
    if (!values.createDate) {
      setFieldValue("createDate", dayjs().format("YYYY-MM-DD"));
    }
  }, []);

  useEffect(() => {
    getClientById({ id: values.clientId }).then((client) => {
      setFieldValue("client", client);
    });
  }, [values.clientId]);

  useEffect(() => {
    if (
      values.invoiceType &&
      values.invoiceType != INVOICE_TYPES.CLIENT_INVOICE &&
      !values.id
    ) {
      const format = getInvoiceFormat(values, INVOICE_TYPES, DRIVER_CODES_DICT);
      getInvoiceNextNumber({
        format,
        createDate: dayjs().format("YYYY-MM-DD"),
        invoiceType: values.invoiceType,
      }).then((response) => {
        setFieldValue("number", response.number);
        setFieldValue("counter", response.counter);
        setFieldValue("format", format);
      });
    }
  }, [values.invoiceType, values.driverId]);

  useEffect(() => {
    if (values.clientId) {
      if (!values.payDate || !values.payType) {
        getClientById({ id: values.clientId }).then((client) => {
          if (!values.payDate) {
            setFieldValue(
              "payDate",
              getPaymentDate({
                client,
                createDate: values.createDate,
                isSelfInvoice:
                  values.invoiceType === INVOICE_TYPES.SELF_INVOICE,
              })
            );
          }
          if (!values.payType) {
            setFieldValue(
              "payType",
              values.invoiceType === INVOICE_TYPES.SELF_INVOICE
                ? client.payType
                : client.selfBillingPayType
            );
          }
        });
      }
    }
  }, [values.clientId]);

  useEffect(() => {
    if (values.isCorrectFor) {
      getInvoiceById({ id: values.isCorrectFor }).then((response) => {
        setFieldValue("originalInvoice", response);
      });
    }
  }, [values.isCorrectFor]);

  useEffect(() => {
    if (values.payStatus === PAY_STATUSES.PAID) {
      setFieldValue("paidDate", dayjs().format("YYYY-MM-DD"));
    } else {
      setFieldValue("paidDate", null);
    }
  }, [values.payStatus, setFieldValue]);

  if (
    !values.invoiceType ||
    !values.clientId ||
    !values.locationId ||
    (!values.isDriver && values.driverId)
  ) {
    return <InitForm />;
  }

  const createConnectedInvoice = () => {
    const { invoiceType } = values;

    const newValues = {
      ...values,
      id: undefined,
    };

    if (invoiceType == INVOICE_TYPES.PROFORMA) {
      newValues.invoiceType = INVOICE_TYPES.INVOICE;
      newValues.connectedInvoiceId = values.id;
    }

    if (invoiceType == INVOICE_TYPES.INVOICE) {
      newValues.invoiceType = INVOICE_TYPES.CORRECTION;
      newValues.isCorrectFor = values.id;
    }
    setValues(newValues);
  };

  const handleInvoicePdf = async () => {
    const payload = await getInvoicePDF({ id: values.id });
    const blob = new Blob([payload], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    window.open(url);
  };

  return (
    <div className="InvoiceEditForm-Module">
      <div className="InvoiceForm">
        <HeaderCard
          title={INVOICE_TYPES_DICT[formikContext.values.invoiceType]}
        >
          {values.id ? (
            <PrimaryButton onClick={() => handleInvoicePdf()}>
              Drukuj
            </PrimaryButton>
          ) : (
            ""
          )}
          {values.hasCorrect && (
            <Popup2
              openButtonText={`Faktura korygująca ${values.hasCorrect.number}`}
              ButtonComponent={SuccessButton}
              component={InvoiceEditFormWrapper}
              modalProps={{ width: 930 }}
              componentProps={{ id: values.hasCorrect.id }}
            />
          )}
          {values.invoiceType == INVOICE_TYPES.PROFORMA && (
            <SuccessButton onClick={createConnectedInvoice}>
              Stwórz fakturę
            </SuccessButton>
          )}
          {values.invoiceType == INVOICE_TYPES.INVOICE &&
            !values.hasCorrect &&
            values.id && (
              <SuccessButton onClick={createConnectedInvoice}>
                Stwórz korektę
              </SuccessButton>
            )}
        </HeaderCard>
        {formikContext.values.invoiceType == INVOICE_TYPES.CLIENT_INVOICE ? (
          <TextInput
            id="invoiceInvoiceNumber"
            placeholder="Nazwa faktury klienta"
          />
        ) : (
          <TextInput id="number" placeholder="Numer faktury" />
        )}
        <FetchListInput configKey="clients" />
        <FetchListInput configKey="locations" />
        {values.isDriver && <FetchListInput configKey="drivers" />}
        <Checkbox id="isDriver" placeholder="Faktura kierowcy" />
        <DateInput id="createDate" placeholder="Data wystawienia" />
        <DateInput id="realisationDate" placeholder="Data realizacji" />
        <DateInput id="payDate" placeholder="Termin płatności" />
        {values.invoiceType !== INVOICE_TYPES.PROFORMA && (
          <SelectInput
            id="payStatus"
            placeholder="Status płatności"
            config={PAY_STATUSES_DICT}
          />
        )}
        {values.payStatus === PAY_STATUSES.PAID && (
          <DateInput
            id="paidDate"
            placeholder="Data opłacenia"
            disabled={true}
          />
        )}
        <SelectInput
          id="payType"
          placeholder="Forma płatności"
          config={PAYMENTS_TYPES_DICT}
        />
        <TextInput id="placeOfCreate" placeholder="Miejsce wystawienia" />
        <TextareaInput id="notes" placeholder="Notatka" />
      </div>

      <Divider>Pozycje</Divider>

      {!values.isCorrectFor ? <InvoiceItemsEditForm /> : <CorrectionItems />}
      <Divider>Załączone dokumenty</Divider>
      <AttachedWasteCards />

      <Divider>Podsumowanie</Divider>

      {!values.isCorrectFor ? <Summary /> : <CorrectSummary />}

      {values.invoiceItems.length > 0 && (
        <ButtonsFooter onClose={onClose} hideSubmit={values.hasCorrect}>
          {values.id && !values.hasCorrect && (
            <DeleteRowButton
              id={formikContext.values.id}
              connector={deleteInvoice}
              onAfterRemove={onClose}
            />
          )}
        </ButtonsFooter>
      )}
    </div>
  );
};

const InvoiceEditForm = ({ data: invoice, onClose }) => {
  const updateCounter = (invoice) => {
    const { format, number } = invoice;
    const nnIndex = format.indexOf("NN");
    if (nnIndex === -1) return invoice;
    const nnPart = number.substring(nnIndex).split("/")[0];
    const counterValue = parseInt(nnPart, 10);
    return {
      ...invoice,
      counter: isNaN(counterValue) ? invoice.counter : counterValue,
    };
  };

  const updateDates = (invoice) => {
    const { createDate, payDate, realisationDate } = invoice;
    return {
      ...invoice,
      createDate: dayjs(createDate).format("YYYY-MM-DD"),
      payDate: dayjs(payDate).format("YYYY-MM-DD"),
      realisationDate: dayjs(realisationDate).format("YYYY-MM-DD"),
    };
  };

  const onSubmit = (values) => {
    const counterFixed = updateCounter(values); //TODO: create paipeline for this
    const datesFixed = updateDates(counterFixed);
    postInvoice({ data: datesFixed }).then(() => {
      onClose();
    });
  };

  return (
    <div className="InvoiceEditForm-Module">
      <FormikWrapper initialValues={initialValues(invoice)} onSubmit={onSubmit}>
        <Form onClose={onClose} />
      </FormikWrapper>
    </div>
  );
};

const InvoiceEditFormWrapper = ({ id = "new", onClose }) => {
  return (
    <FetchWrapper
      onClose={onClose}
      component={InvoiceEditForm}
      id={id}
      connector={() => getInvoiceById({ id })}
      errorMessage="Nie można pobrać danych faktury."
    />
  );
};

export default InvoiceEditFormWrapper;
