import { columnsConfig } from "./config";
import SearchForm from "./SearchForm";
import { getSpecialPricesByClientId } from "connector";
import SpecialPricesEditForm from "modules/SpecialPricesEditForm";
import AdvancedTable2 from "components/AdwancedTable2";

const SpecialPricesTable = ({ clientId }) => {
  return (
    <AdvancedTable2
      title="Ceny specjalne"
      columns={columnsConfig()}
      onFetch={(params) => getSpecialPricesByClientId({ ...params, clientId })}
      FilterComponent={SearchForm}
      EditAddComponent={SpecialPricesEditForm}
      clientId={clientId}
    />
  );
};

export default SpecialPricesTable;
