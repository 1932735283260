import dayjs from "dayjs";

export const getPaymentDate = ({ client, createDate, isSelfInvoice }) => {
  const payDelay =
    (isSelfInvoice ? client.selfBillingPayDelay : client.payDelay) || 0;
  const configmedCreateDate = createDate
    ? createDate
    : dayjs().format("YYYY-MM-DD");
  return dayjs(configmedCreateDate).add(payDelay, "day").format("YYYY-MM-DD");
};
