import { columnsConfig } from "./config";
import SearchForm from "./SearchForm";
import { useConfig } from "providers/configProvider";
import { getInvoices } from "connector";
import AdvancedTable2 from "components/AdwancedTable2";
import InvoiceEditForm from "modules/InvoiceEditForm";
import PaymentStatusButton from "components/PaymentStatusButton";

const InvoicesTable = (props) => {
  const { PAY_STATUSES_DICT, INVOICE_TYPES_DICT } = useConfig();

  return (
    <AdvancedTable2
      title="Faktury"
      columns={columnsConfig({ PAY_STATUSES_DICT, INVOICE_TYPES_DICT })}
      onFetch={getInvoices}
      FilterComponent={SearchForm}
      EditAddComponent={InvoiceEditForm}
      render={(invoice, fetchData) => (
        <PaymentStatusButton invoice={invoice} refreshTable={fetchData} />
      )}
      {...props}
    />
  );
};

export default InvoicesTable;
