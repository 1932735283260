import { NavLink, useNavigate } from "react-router-dom";
import { navConfig } from "./Header.config";
import "./Header.scss";
import Navigation from "components/Navigation";
import { useAuth } from "providers/AuthProvider";
import { LogoutOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

const Header = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  
  return (
    <header className="Header">
      <div className="logo">
        <NavLink
          to="/"
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          DirectDesk
        </NavLink>
      </div>
      <div className="nav-container">
        <Navigation config={navConfig} />
        <Tooltip title="Wyloguj">
          <LogoutOutlined
            className="logout-button"
            onClick={() => {
              logout();
              navigate("/login");
            }}
          />
        </Tooltip>
      </div>
    </header>
  );
};

export default Header;
