import { columnsConfig } from "./config";
import { getSuppliers } from "connector";
import SupplierEditForm from "modules/SupplierEditForm";
import SearchForm from "./SearchForm";
import AdvancedTable2 from "components/AdwancedTable2";

const SuppliersTable = () => {
  return (
    <AdvancedTable2
      title="Dostawcy"
      columns={columnsConfig()}
      onFetch={getSuppliers}
      FilterComponent={SearchForm}
      EditAddComponent={SupplierEditForm}
    />
  );
};

export default SuppliersTable;
