import dayjs from "dayjs";
const formatDatesByKeys = ({ keys, data }) => {
  const formattedData = { ...data };

  keys.forEach((key) => {
    if (data[key]) {
      formattedData[key] = dayjs(data[key]).format("YYYY-MM-DD");
    }
  });

  return formattedData;
};

export default formatDatesByKeys;
