import "./HeaderCard.scss";

const HeaderCard = ({ title, children }) => (
  <div className="HeaderCard-Component">
    <div className="title">{title}</div>
    <div className="actions">{children}</div>
  </div>
);

export default HeaderCard;
