import { MinusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import TextInput from "components/FormikWrapper/FormControls/Input";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import { calculateBrutto, calculateNetto } from "utils/cashUtils";
import { sanitizeQuantity } from "utils/sanitizeQuantity";

const ItemEdit = ({ index }) => {
  const formikContext = useFormikContext();
  const {
    setFieldValue,
    values: { invoiceItems },
  } = formikContext;
  const item = invoiceItems[index];

  const formikValueKey = `invoiceItems[${index}]`;

  useEffect(() => {
    if (!item) return;
    const brutto = calculateBrutto(
      item.nettoUnitPrice,
      item.vat,
      item.quantity
    );
    setFieldValue(`${formikValueKey}.bruttoSummaryPrice`, brutto);
  }, []);

  const handleVatChange = (e) => {
    const vat = sanitizeQuantity(e.target.value);
    const brutto = calculateBrutto(item.nettoUnitPrice, vat, item.quantity);
    setFieldValue(`${formikValueKey}.vat`, vat);
    setFieldValue(`${formikValueKey}.bruttoSummaryPrice`, brutto);
  };

  const handleNettoChange = (e) => {
    const netto = sanitizeQuantity(e.target.value);
    const brutto = calculateBrutto(netto, item.vat, item.quantity);
    setFieldValue(`${formikValueKey}.nettoUnitPrice`, netto);
    setFieldValue(`${formikValueKey}.bruttoSummaryPrice`, brutto);
  };

  const handleBruttoChange = (e) => {
    const brutto = sanitizeQuantity(e.target.value);
    const netto = calculateNetto(brutto, item.vat, item.quantity);
    setFieldValue(`${formikValueKey}.bruttoSummaryPrice`, brutto);
    setFieldValue(`${formikValueKey}.nettoUnitPrice`, netto);
  };

  const handleQuantityChange = (e) => {
    const quantity = sanitizeQuantity(e.target.value);
    const brutto = calculateBrutto(item.nettoUnitPrice, item.vat, quantity);
    setFieldValue(`${formikValueKey}.quantity`, quantity);
    setFieldValue(`${formikValueKey}.bruttoSummaryPrice`, brutto);
  };

  const RemoveItem = (index) => {
    setFieldValue(
      "invoiceItems",
      invoiceItems.filter((_, i) => i !== index)
    );
  };

  return (
    <div className="item">
      <div className="product-name">{item.product.name}</div>
      <TextInput
        id={`${formikValueKey}.quantity`}
        placeholder="Ilość"
        onChange={(e) => handleQuantityChange(e)}
      />
      <div className="unit-name">{item.product.unitName}</div>

      <TextInput
        id={`${formikValueKey}.nettoUnitPrice`}
        placeholder="Cena sztuki netto"
        onChange={(e) => handleNettoChange(e)}
      />
      <TextInput
        id={`${formikValueKey}.vat`}
        placeholder="VAT"
        onChange={(e) => handleVatChange(e)}
      />
      <TextInput
        id={`${formikValueKey}.bruttoSummaryPrice`}
        placeholder="Wartość Brutto"
        onChange={(e) => handleBruttoChange(e)}
      />
      <Button
        shape="circle"
        variant="solid"
        color="red"
        onClick={() => RemoveItem(index)}
      >
        <MinusOutlined />
      </Button>
    </div>
  );
};

export default ItemEdit;
