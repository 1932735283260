import dayjs from "dayjs";

export const columnsConfig = ({ INVOICE_TYPES_DICT }) => [
  {
    title: "Numer",
    dataIndex: "number",
    sorter: true,
  },
  {
    title: "Klient",
    render: (data) => `${data.client.name}`,
  },
  {
    title: "Data wystawienia",
    render: (createDate) => dayjs(createDate).format("DD-MM-YYYY"),
    dataIndex: "createDate",
    sorter: true,
  },
  {
    title: "Typ faktury",
    render: (invoiceType) => INVOICE_TYPES_DICT[invoiceType],
    dataIndex: "invoiceType",
    sorter: true,
  },
  {
    title: "Wartość brutto",
    dataIndex: "totalBruttoPrice",
    render: (totalBruttoPrice) => `${totalBruttoPrice} zł`,
    sorter: true,
  },
];
