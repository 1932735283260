import { Button } from "antd";

export const PrimaryButton = ({ children, ...props }) => (
  <Button type="primary" color="blue" variant="solid" {...props}>
    {children}
  </Button>
);

export const RadiusPrimaryButton = ({ children, ...props }) => (
  <PrimaryButton shape="circle" {...props}>
    {children}
  </PrimaryButton>
);

export const SuccessButton = ({ children, ...props }) => (
  <Button color="green" variant="solid" {...props}>
    {children}
  </Button>
);

export const RadiusSuccesButton = ({ children, ...props }) => (
  <SuccessButton shape="circle" {...props}>
    {children}
  </SuccessButton>
);

export const WarningButton = ({ children, ...props }) => (
  <Button color="yellow" variant="solid" {...props}>
    {children}
  </Button>
);

export const RadiusWarningButton = ({ children, ...props }) => (
  <WarningButton shape="circle" {...props}>
    {children}
  </WarningButton>
);

export const DangerButton = ({ children, ...props }) => (
  <Button color="red" variant="solid" {...props}>
    {children}
  </Button>
);

export const RadiusDangerButton = ({ children, ...props }) => (
  <DangerButton shape="circle" {...props}>
    {children}
  </DangerButton>
);
