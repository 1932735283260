import dayjs from "dayjs";

export const initialValues = (payload) => ({
  isDriver: payload.driverId ? true : false,
  id: null,
  number: "",
  clientId: null,
  clientInvoiceNumber: null,
  connectedInvoiceId: null,
  createDate: dayjs().format("YYYY-MM-DD"),
  realisationDate: dayjs().format("YYYY-MM-DD"),
  routeId: null,
  counter: null,
  format: "",
  invoiceType: null,
  isCorrectFor: null,
  notes: "",
  payDate: null,
  placeOfCreate: "",
  payStatus: "",
  paidDate: null,
  totalBruttoPrice: 0,
  invoiceItems: [],
  ...payload,
});
