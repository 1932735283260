import AdvancedTable2 from "components/AdwancedTable2";
import { columnsConfig } from "./config";
import SearchForm from "./SearchForm";
import { getLocationsByClientId } from "connector";
import LocationEditForm from "modules/LocationEditForm";

const LocationsTable = ({ clientId, ...rest }) => {
  return (
    <div>
      <AdvancedTable2
        title="Lokalizacje"
        columns={columnsConfig()}
        onFetch={(params) => getLocationsByClientId({ ...params, clientId })}
        FilterComponent={SearchForm}
        EditAddComponent={LocationEditForm}
        clientId={clientId}
        {...rest}
      />
    </div>
  );
};

export default LocationsTable;
