import { useState } from "react";
import dayjs from "dayjs";
import HeaderCard from "components/HeaderCard";
import DateSelector from "components/DateSelector";
import "dayjs/locale/pl";
import { routesByDates } from "connector";
import FetchWrapper from "components/FetchWrapper";
import "./RoutesManager.scss";
import RoutesCard from "./RoutesCard";
dayjs.locale("pl");

const RoutesCalendar = ({ startDate, daysCount, routes, refetch }) => {
  return Array.from({ length: daysCount }, (_, i) => {
    const currentDate = dayjs(startDate).add(i, "day");
    const formattedDate = currentDate.format("YYYY-MM-DD");

    const routesByDate = routes.filter(
      (route) => dayjs(route.date).format("YYYY-MM-DD") === formattedDate
    );

    return (
      <RoutesCard
        key={currentDate}
        routes={routesByDate}
        currentDate={formattedDate}
        refetch={refetch}
      />
    );
  });
};

const RouteManager = ({ refetch, data: routes, startDate, daysCount }) => {
  return (
    <div className="RoutesManager-Module">
      <div className="calendar">
        <RoutesCalendar
          startDate={startDate}
          daysCount={daysCount}
          routes={routes.data}
          refetch={refetch}
        />
      </div>
    </div>
  );
};

const RoutesManagerWrapper = () => {
  const [startDate, setStartDate] = useState(dayjs().format("YYYY-MM-DD"));
  const daysCount = 12;

  return (
    <>
      <HeaderCard title="Trasy" />
      <DateSelector
        startDate={startDate}
        setStartDate={setStartDate}
        daysCount={daysCount}
      />
      <FetchWrapper
        key={startDate}
        startDate={startDate}
        daysCount={daysCount}
        setStartDate={setStartDate}
        component={RouteManager}
        connector={() =>
          routesByDates({
            params: {
              filters: {
                date_min: startDate,
                date_max: dayjs(startDate)
                  .add(daysCount, "day")
                  .format("YYYY-MM-DD"),
              },
            },
          })
        }
      />
    </>
  );
};

export default RoutesManagerWrapper;
