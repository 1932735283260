import { initialValues } from "./initialValues";
import {
  getSpecialPriceById,
  postSpecialPrice,
  deleteSpecialPrice,
} from "connector";
import FormikWrapper from "components/FormikWrapper";
import TextInput from "components/FormikWrapper/FormControls/Input";
import "./SpecialPricesEditForm.scss";
import ButtonsFooter from "components/FormikWrapper/FormControls/FooterButtons";
import FetchWrapper from "components/FetchWrapper";
import FetchListInput from "components/FormikWrapper/FormControls/FetchList";
import DeleteRowButton from "components/DeleteRowButton";

const SpecialPricesEditForm = ({ data: specialPrices, onClose, clientId }) => {
  if (clientId) {
    specialPrices.clientId = clientId;
  }
  const onSubmit = (values) => {
    postSpecialPrice({ data: values }).then(() => {
      onClose();
    });
  };

  return (
    <div className="SpecialPrices-Module">
      <FormikWrapper
        initialValues={initialValues({ ...specialPrices })}
        onSubmit={onSubmit}
      >
        <div className="SpecialPrices-module">
          <FetchListInput configKey="products" />
          <TextInput
            type="decimal"
            id="specialPrice"
            placeholder="Cena specjalna"
          />

          <ButtonsFooter onClose={onClose}>
            {specialPrices.id && (
              <DeleteRowButton
                id={specialPrices.id}
                connector={deleteSpecialPrice}
                onAfterRemove={onClose}
              />
            )}
          </ButtonsFooter>
        </div>
      </FormikWrapper>
    </div>
  );
};

const SpecialPricesEditFormWrapper = ({
  id: specialPricesId,
  onClose,
  clientId,
}) => {
  return (
    <FetchWrapper
      onClose={onClose}
      clientId={clientId}
      component={SpecialPricesEditForm}
      id={specialPricesId}
      connector={() => getSpecialPriceById({ id: specialPricesId })}
      errorMessage="Nie można pobrać danych ceny."
    />
  );
};

export default SpecialPricesEditFormWrapper;
