import { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { sidebarNavConfig } from "./Warehouse.config";
import Navigation from "components/Navigation";
import Products from "./Products";
import Categories from "./Categories";
import Suppliers from "./Suppliers";

const Warehouse = () => {
  const path = window.location.pathname;
  const navigate = useNavigate();

  useEffect(() => {
    if (path === "/warehouse") {
      navigate("/warehouse/products");
    }
  }, [path, navigate]);

  return (
    <div className="with-sidebar">
      <div className="sidebar">
        <Navigation config={sidebarNavConfig} />
      </div>
      <div className="content">
        <Routes>
          <Route path="/products" element={<Products />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/suppliers" element={<Suppliers />} />
        </Routes>
      </div>
    </div>
  );
};

export default Warehouse;
