import { initialValues } from "./initialValues";
import { getProductById, postProduct, deleteProduct } from "connector";
import FormikWrapper from "components/FormikWrapper";
import TextInput from "components/FormikWrapper/FormControls/Input";
import "./ProductEditForm.scss";
import FetchWrapper from "components/FetchWrapper";
import ButtonsFooter from "components/FormikWrapper/FormControls/FooterButtons";
import FetchListInput from "components/FormikWrapper/FormControls/FetchList";
import CheckboxInput from "components/FormikWrapper/FormControls/Checkbox";
import DeleteRowButton from "components/DeleteRowButton";

const ProductEditForm = ({ data: product, onClose }) => {
  const onSubmit = async (values) => {
    let preparedValues = values;
    if (values.sku === "") {
      preparedValues.sku = values.name;
    }

    await postProduct({ data: values }).then(() => {
      onClose();
    });
  };

  return (
    <div className="ProductEditForm-Module">
      <FormikWrapper initialValues={initialValues(product)} onSubmit={onSubmit}>
        <div className="ProductEditForm-module">
          <TextInput id="name" placeholder="Nazwa" />
          <FetchListInput configKey="categories" />
          <TextInput id="sku" placeholder="SKU" />
          <TextInput
            type="decimal"
            id="nettoUnitPrice"
            placeholder="Domyślna Cena netto"
          />
          <TextInput type="decimal" id="vat" placeholder="Vat" />
          <TextInput type="decimal" id="totalQuantity" placeholder="Ilość" />
          <TextInput id="unitName" placeholder="Jednostka" />
          <TextInput
            type="decimal"
            id="minStock"
            placeholder="Minimalny stan magazynowy"
          />
          <TextInput id="location" placeholder="Lokalizacja" />
          <CheckboxInput id="isPurchase" placeholder="Produkt zakupowy" />
          <ButtonsFooter onClose={onClose}>
            {product.id && (
              <DeleteRowButton
                id={product.id}
                connector={deleteProduct}
                onAfterRemove={onClose}
              />
            )}
          </ButtonsFooter>
        </div>
      </FormikWrapper>
    </div>
  );
};

const ProductEditFormWrapper = ({ id, onClose }) => (
  <FetchWrapper
    onClose={onClose}
    component={ProductEditForm}
    id={id}
    connector={() => getProductById({ id })}
    errorMessage="Nie można pobrać danych produktu."
  />
);

export default ProductEditFormWrapper;
