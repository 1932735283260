import { useEffect, useState } from "react";
import { Button, Modal } from "antd";

const Popup2 = ({
  buttonProps,
  openCondition = false,
  openButtonText = "Otwórz Popup",
  ButtonComponent = Button,
  component,
  componentProps,
  afterClose,
  children,
  modalProps,
}) => {
  const [isOpen, setIsOpen] = useState(openCondition);
  const [initialized, setInitialized] = useState(false);
  const Component = component;

  useEffect(() => {
    setIsOpen(openCondition);
  }, [openCondition]);

  useEffect(() => {
    if (initialized && !isOpen && afterClose) {
      afterClose();
    } else {
      setInitialized(true);
    }
  }, [isOpen]);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <ButtonComponent {...buttonProps} onClick={togglePopup}>
        {openButtonText}
      </ButtonComponent>
      <Modal footer={[]} open={isOpen} onCancel={togglePopup} {...modalProps}>
        <Component onClose={() => setIsOpen(false)} {...componentProps} />
        {children}
      </Modal>
    </>
  );
};

export default Popup2;
