export const getInvoiceFormat = (
  invoiceContext,
  INVOICE_TYPES,
  DRIVER_CODES_DICT
) => {
  const type = invoiceContext.invoiceType;

  if (type === INVOICE_TYPES.CLIENT_INVOICE) {
    return "";
  }

  let format = "NN/MM/YYYY";
  if (type === INVOICE_TYPES.SELF_INVOICE || type === INVOICE_TYPES.INVOICE) {
    const driverCode = DRIVER_CODES_DICT[invoiceContext.driverId];
    const code = driverCode ? `/${driverCode}` : "";
    return (format = `NN/MM/YYYY${code}`);
  }
  if (type === INVOICE_TYPES.PROFORMA) {
    return `PROF/NN/MM/YYYY/`;
  }
  if (type === INVOICE_TYPES.CORRECTION) {
    return `KOR/NN/MM/YYYY/`;
  }
  return format;
};

export const vatSelector = ({
  client,
  product,
  invoiceType,
  GLOBAL_CONFIG,
}) => {
  const { FALSE } = GLOBAL_CONFIG.TRUE_FALSE;
  const { SELF_INVOICE } = GLOBAL_CONFIG.INVOICE_TYPES;
  return client.isVat == FALSE && invoiceType == SELF_INVOICE ? 0 : product.vat;
};
