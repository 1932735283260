import LoadingSpiner from "components/LoadingSpiner";
import apiClient from "config/axios";
import React, { createContext, useContext, useState, useEffect } from "react";

const ConfigContext = createContext();

export const ConfigProvider = ({ children }) => {
  const [config, setConfig] = useState(null);

  useEffect(() => {
    apiClient("/config")
      .then((payload) => setConfig(payload.data))
      .catch((error) => {
        alert(
          "Wystąpił błąd podczas pobierania konfiguracji \n" +
            JSON.stringify(error.response.data.message)
        );
      }); // Endpoint backendowy
  }, []);

  if (!config) {
    return <LoadingSpiner text="Ładowanie konfiguracji" />; // Loader w trakcie pobierania
  }

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};

export const useConfig = () => {
  const context = useContext(ConfigContext);
  if (!context) {
    throw new Error("useConfig must be used within a ConfigProvider");
  }
  return context;
};
