import { Button } from "antd";
import Popup2 from "components/Popup2";

const DeleteRowButton = ({ onAfterRemove, connector, id }) => {
  if (!id) return null;

  const handleClick = async () => {
    await connector({ id });
    onAfterRemove();
  };

  const comfirmComponent = ({ onClose }) => (
    <div>
      <h2>Czy na pewno chcesz usunąć ten rekord?</h2>
      <div className="text-center">
        <Button
          type="primary"
          color="red"
          variant="solid"
          onClick={() => {
            handleClick();
            onClose();
          }}
        >
          Usuń
        </Button>
        <Button onClick={onClose}>Anuluj</Button>
      </div>
    </div>
  );

  return (
    <>
      <Popup2
        buttonProps={{ type: "primary", color: "red", variant: "solid" }}
        openButtonText="Usuń"
        component={comfirmComponent}
      />
    </>
  );
};

export default DeleteRowButton;
