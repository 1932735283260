import columnsConfig from "./config";
import SearchForm from "./SearchForm";
import { getDrivers } from "connector";
import DriverEditForm from "modules/DriverEditForm";
import AdvancedTable2 from "components/AdwancedTable2";

const DriversTable = (props) => {
  return (
    <AdvancedTable2
      title="Kierowcy"
      columns={columnsConfig()}
      onFetch={getDrivers}
      FilterComponent={SearchForm}
      EditAddComponent={DriverEditForm}
      {...props}
    />
  );
};

export default DriversTable;
