import { useEffect } from "react";
import { useFormikContext } from "formik";
import { calculateSummaryNetto, calculateSummaryBrutto } from "utils/cashUtils";
const Summary = () => {
  const formikContext = useFormikContext();
  const { invoiceItems } = formikContext.values;
  const summaryBrutton = calculateSummaryBrutto(invoiceItems);
  const summaryNetto = calculateSummaryNetto(invoiceItems);

  useEffect(() => {
    formikContext.setFieldValue("totalBruttoPrice", summaryBrutton);
  }, [summaryBrutton]);

  return (
    <div className="Summary-invoice-module">
      <div>
        Brutto: <span className="brutto">{summaryBrutton}</span>
        PLN
      </div>
      <div>
        Netto: <span className="netto">{summaryNetto}</span>
        PLN
      </div>
    </div>
  );
};

export default Summary;
