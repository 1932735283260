import { DangerButton } from "components/Buttons";
import dayjs from "dayjs";

export const columnsConfig = ({ removeWasteCard }) => [
  {
    title: "Numer Karty",
    dataIndex: "wasteNumber",
    sorter: true,
  },
  {
    title: "Ilość",
    dataIndex: "quantity",
  },
  {
    title: "Data odebrania",
    render: (item) => {
      return dayjs(item.transferDate).format("DD.MM.YYYY");
    },
  },
  {
    title: "Kierowca",
    render: (item) => {
      return `${item.driver.firstName} ${item.driver.lastName}`;
    },
  },
  {
    title: "Remove",
    render: (item) => {
      return (
        <DangerButton onClick={() => removeWasteCard(item.id)}>
          Usuń
        </DangerButton>
      );
    },
  },
];
