import FormikWrapper from "components/FormikWrapper";
import FetchWrapper from "components/FetchWrapper";
import ButtonsFooter from "components/FormikWrapper/FormControls/FooterButtons";
import TextInput from "components/FormikWrapper/FormControls/Input";
import { getDriverById, postDriver, deleteDriver } from "connector";
import { initialValues } from "./initialValues";
import "./DriverEditForm.scss";
import SelectInput from "components/FormikWrapper/FormControls/Select";
import { useConfig } from "providers/configProvider";
import DeleteRowButton from "components/DeleteRowButton";

const DriverEditForm = ({ data: driver, onClose }) => {
  const { EMPLOYEE_STATUS_DICT } = useConfig();

  const onSubmit = async (values) => {
    await postDriver({ data: values }).then(() => {
      onClose();
    });
  };

  return (
    <div className="DriverEditForm-Module">
      <FormikWrapper initialValues={initialValues(driver)} onSubmit={onSubmit}>
        <div className="DriverEditForm-module">
          <TextInput id="login" placeholder="Login" />
          <TextInput id="password" placeholder="Hasło" />
          <TextInput id="firstName" placeholder="Imię" />
          <TextInput id="lastName" placeholder="Nazwisko" />
          <TextInput id="email" placeholder="Email" />
          <TextInput id="phoneNumber" placeholder="Telefon" />
          <TextInput id="employeeCode" placeholder="Kod pracownika" />
          <TextInput
            id="registrationNumber"
            placeholder="Numer rejestracyjny"
          />
          <SelectInput
            id="status"
            placeholder="Status"
            config={EMPLOYEE_STATUS_DICT}
          />

          <ButtonsFooter onClose={onClose}>
            {driver.id && (
              <DeleteRowButton
                id={driver.id}
                connector={deleteDriver}
                onAfterRemove={onClose}
              />
            )}
          </ButtonsFooter>
        </div>
      </FormikWrapper>
    </div>
  );
};

const DriverEditFormWrapper = ({ id, onClose }) => {
  return (
    <FetchWrapper
      onClose={onClose}
      component={DriverEditForm}
      id={id}
      connector={() => getDriverById({ id })}
      errorMessage="Nie można pobrać danych produktu."
    />
  );
};

export default DriverEditFormWrapper;
