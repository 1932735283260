import { useNavigate } from "react-router-dom";
import { useConfig } from "providers/configProvider";

import FetchWrapper from "components/FetchWrapper";
import { useFormikContext } from "formik";
import TextInput from "components/FormikWrapper/FormControls/Input";
import FormikWrapper from "components/FormikWrapper";
import SelectInput from "components/FormikWrapper/FormControls/Select";
import { getClientById, postClient, deleteClient } from "connector";
import Divider from "components/Divider";

import CeidgSync from "./CeidgSync";
import { initialValues } from "./initialValues";
import ButtonsFooter from "components/FormikWrapper/FormControls/FooterButtons";
import DeleteRowButton from "components/DeleteRowButton";
import TextareaInput from "components/FormikWrapper/FormControls/Textarea";

const Form = () => {
  const navigate = useNavigate();
  const { PAYMENTS_TYPES_DICT, TRUE_FALSE_DICT } = useConfig();
  const { values } = useFormikContext();

  return (
    <>
      <TextInput id="name" placeholder="Nazwa Firmy" />
      <TextInput id="clientName" placeholder="Nazwa klienta" />
      <TextInput id="city" placeholder="Miasto" />
      <TextInput id="address" placeholder="Adres" />
      <TextInput id="nip" placeholder="NIP" />
      <TextInput id="regon" placeholder="Regon" />
      <TextInput id="country" placeholder="Kraj" />
      <TextInput id="postalCode" placeholder="Kod pocztowy" />
      <TextInput id="phone" placeholder="Telefon" />
      <TextInput id="mobilePhone" placeholder="Telefon komórkowy" />
      <TextInput id="email" placeholder="Email" />
      <SelectInput
        id="isVat"
        placeholder="Jest Vat-owcem?"
        config={TRUE_FALSE_DICT}
      />
      <TextInput id="bankAccountNumber" placeholder="Numer konta bankowego" />
      <SelectInput
        id="payType"
        placeholder="Typ płatności"
        config={PAYMENTS_TYPES_DICT}
      />
      <TextInput id="payDelay" placeholder="Opóźnienie płatności" />
      <TextareaInput id="driverNotes" placeholder="Notatki dla kierowcy" />

      <Divider>Samofaktura klienta - ustawienia</Divider>

      <SelectInput
        id="isSelfBilling"
        placeholder="Samofakturyzacja"
        config={TRUE_FALSE_DICT}
      />
      <SelectInput
        id="selfBillingPayType"
        placeholder="Typ płatności samofaktury"
        config={PAYMENTS_TYPES_DICT}
      />
      <TextInput
        id="selfBillingPayDelay"
        placeholder="Opóźnienie płatności samofaktury"
      />

      <CeidgSync />

      <ButtonsFooter onClose={() => navigate("/clients")}>
        {values.id && (
          <DeleteRowButton
            id={values.id}
            connector={deleteClient}
            onAfterRemove={() => navigate("/clients")}
          />
        )}
      </ButtonsFooter>
    </>
  );
};

const ClientEditForm = ({ data: client, onClose }) => {
  const onSubmit = async (values) => {
    await postClient({ data: values }).then(() => {
      window.location.href = `/clients/${client.id}`;
    });
  };

  return (
    <div className="ClientEditForm-Module">
      <FormikWrapper initialValues={initialValues(client)} onSubmit={onSubmit}>
        <Form />
      </FormikWrapper>
    </div>
  );
};

const ClientEditFormWrapper = ({ id = "new", onClose }) => (
  <FetchWrapper
    onClose={onClose}
    component={ClientEditForm}
    id={id}
    connector={() => getClientById({ id })}
    errorMessage="Nie można pobrać danych klienta."
  />
);

export default ClientEditFormWrapper;
