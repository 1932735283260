import RoutesCalendar from "modules/RoutesCalendar";

const Routes = () => {
  return (
    <div>
      <RoutesCalendar />
    </div>
  );
};

export default Routes;
