export const columnsConfig = () => [
  { title: "Nazwa", dataIndex: "name", sorter: true },
  {
    title: "Klient",
    render: (item) => {
      return item.client.name;
    },
  },
  { title: "Miasto", dataIndex: "city", sorter: true },
  { title: "Adres", dataIndex: "address", sorter: true },
];
