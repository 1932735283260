import { Dropdown } from "antd";
import { SuccessButton, DangerButton, PrimaryButton } from "components/Buttons";
import { postInvoice } from "connector";
import dayjs from "dayjs";
import { useConfig } from "providers/configProvider";

const PaymentStatusButton = ({ refreshTable, invoice }) => {
  const { PAY_STATUSES, PAY_STATUSES_DICT } = useConfig();
  const { id, payStatus, payDate, invoiceItems } = invoice;

  console.log(invoice, payDate);

  const handleStatusChange = async (newStatus) => {
    const paidDate =
      newStatus === PAY_STATUSES.PAID ? dayjs().format("YYYY-MM-DD") : null;

    await postInvoice({
      data: {
        id,
        payStatus: newStatus,
        paidDate,
        invoiceItems,
      },
    });

    refreshTable();
  };

  const items = [
    {
      key: PAY_STATUSES.PAID,
      label: PAY_STATUSES_DICT[PAY_STATUSES.PAID],
      onClick: () => handleStatusChange(PAY_STATUSES.PAID),
    },
    {
      key: PAY_STATUSES.UNPAID,
      label: PAY_STATUSES_DICT[PAY_STATUSES.UNPAID],
      onClick: () => handleStatusChange(PAY_STATUSES.UNPAID),
    },
  ];

  const ButtonComponent =
    payStatus === PAY_STATUSES.PAID
      ? SuccessButton
      : payDate && dayjs(payDate).isAfter(dayjs(), "day")
      ? PrimaryButton
      : DangerButton;
  const buttonText = PAY_STATUSES_DICT[payStatus];

  return (
    <Dropdown menu={{ items }} trigger={["click"]}>
      <ButtonComponent>{buttonText}</ButtonComponent>
    </Dropdown>
  );
};

export default PaymentStatusButton;
