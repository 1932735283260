import { PrimaryButton } from "components/Buttons";
import FormikWrapper from "components/FormikWrapper";
import DateInput from "components/FormikWrapper/FormControls/Date";
import FetchListInput from "components/FormikWrapper/FormControls/FetchList";
import { getWasteCardXLS } from "connector";
import dayjs from "dayjs";

const Form = () => {
  return (
    <>
      <DateInput id="transferDate_min" placeholder="Data początkowa" />
      <DateInput id="transferDate_max" placeholder="Data końcowa" />
      <FetchListInput configKey="clients" />
      <FetchListInput configKey="locations" />
      <FetchListInput configKey="drivers" />
      <div className="button-container">
        <PrimaryButton htmlType="submit">Generuj</PrimaryButton>
      </div>
    </>
  );
};

const WasteCardXLSGenerator = () => {
  const onSubmit = async (values) => {
    const payload = await getWasteCardXLS({ data: values });
    const blob = new Blob([payload], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    window.open(url);
  };

  return (
    <div className="WasteCardXLSGenerator-Modules">
      <FormikWrapper
        initialValues={{
          transferDate_min: dayjs().format("YYYY-MM-DD"),
          transferDate_max: dayjs().format("YYYY-MM-DD"),
        }}
        onSubmit={onSubmit}
      >
        <Form />
      </FormikWrapper>
    </div>
  );
};

export default WasteCardXLSGenerator;
