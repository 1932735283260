import InvoicesTable from "modules/InvoicesTable";
import { PrimaryButton } from "components/Buttons";
import Popup2 from "components/Popup2";
import InvoicesRaportGenerator from "modules/InvoicesRaportGenerator";

const Invoices = () => {
  return (
    <div>
      <InvoicesTable
        headerChildren={["epp", "csv"].map((type) => (
          <Popup2
            componentProps={{ type }}
            ButtonComponent={PrimaryButton}
            component={InvoicesRaportGenerator}
            openButtonText={`Generuj ${type.toUpperCase()}`}
          />
        ))}
      />
    </div>
  );
};

export default Invoices;
