import CategoriesTable from "modules/CategoriesTable";

const Categories = () => {
  return (
    <div>
      <CategoriesTable />
    </div>
  );
};

export default Categories;
