import { BrowserRouter } from "react-router-dom";
import Root from "./Root";
import "./App.scss";
import { ConfigProvider } from "providers/configProvider";
import { AuthProvider } from "providers/AuthProvider";

function App() {
  return (
    <>
      <BrowserRouter>
        <AuthProvider>
          <ConfigProvider>
            <Root />
          </ConfigProvider>
        </AuthProvider>
      </BrowserRouter>
      <div className="version">1.0.1</div>
    </>
  );
}

export default App;
