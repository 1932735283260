export const columnsConfig = () => [
  {
    title: "Name",
    dataIndex: "name",
    sorter: true,
    width: "20%",
  },
  {
    title: "Kategorie",
    render: (item) => {
      return item?.categories?.name || "Błędna kategoria";
    },
  },
  {
    title: "SKU",
    dataIndex: "sku",
    sorter: true,
  },
  {
    title: "Ilość",
    dataIndex: "totalQuantity",
    sorter: true,
  },
  {
    title: "Jednostka",
    dataIndex: "unitName",
    sorter: true,
  },
];
