import { initialValues } from "./initialValues";
import { getSupplierById, postSupplier, deleteSupplier } from "connector";
import FormikWrapper from "components/FormikWrapper";
import TextInput from "components/FormikWrapper/FormControls/Input";
import TextareaInput from "components/FormikWrapper/FormControls/Textarea";
import "./SupplierEditForm.scss";
import ButtonsFooter from "components/FormikWrapper/FormControls/FooterButtons";
import FetchWrapper from "components/FetchWrapper";
import DeleteRowButton from "components/DeleteRowButton";

const SupplierEditForm = ({ data: supplier, onClose }) => {
  const onSubmit = (values) => {
    postSupplier({ data: values }).then(() => {
      onClose();
    });
  };

  return (
    <div className="SupplierEditForm-Module">
      <FormikWrapper
        initialValues={initialValues(supplier)}
        onSubmit={onSubmit}
      >
        <div className="SupplierEditForm-module">
          <TextInput id="name" placeholder="Nazwa" />
          <TextareaInput id="contactInfo" placeholder="Dane kontaktowe" />

          <ButtonsFooter onClose={onClose}>
            {supplier.id && (
              <DeleteRowButton
                id={supplier.id}
                connector={deleteSupplier}
                onAfterRemove={onClose}
              />
            )}
          </ButtonsFooter>
        </div>
      </FormikWrapper>
    </div>
  );
};

const SupplierEditFormWrapper = ({ id, onClose }) => (
  <FetchWrapper
    onClose={onClose}
    component={SupplierEditForm}
    id={id}
    connector={() => getSupplierById({ id })}
    errorMessage="Nie można pobrać danych dostawcy."
  />
);

export default SupplierEditFormWrapper;
